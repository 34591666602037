import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, isObservable, map, Observable, of, Subject } from 'rxjs';
import { OperationObserverService } from '../../operation';
import { makeId } from '../../utils';
import { IDataPageModel, IDataSortModel } from '../types/data-model';
import { IDataTreeProvider } from '../types/data-provider';
import { SelectionState } from './data-table-view';

export interface IDataNode<T = any, I = any> {
  id: I;
  parentId: I | null;
  data: T;
  childCount: number;
}

export interface IDataTreeViewOptions<T = any, F = any, A = any> {
  filter?: F;
  args?: A;
  page?: IDataPageModel;
  sort?: IDataSortModel;
  filterFixture?: (filter: F) => F | null | undefined;
  filterSubject?: Observable<F | null | undefined>;
  displayOptions?: any;
}

/**
 * - Save expanded state
 * - Pagination for root and each expanable item
 */
export class DataTreeView<T = any, F = any, A = any, I = any> implements DataSource<T> {
  get id() {
    return this._id;
  }

  get stateId() {
    return this._stateId;
  }

  get stateTitle() {
    return this._stateTitle;
  }

  get itemLoadingCount() {
    return this._loadingModel.selected.length ?? 0;
  }

  get active() {
    return this.getActive();
  }

  itemLoadingState: SelectionState = 'none';

  private readonly _id: string;
  private _stateId: string = makeId(32);
  private _stateTitle?: string | null;

  protected _activeModel = new SelectionModel<I>(false);
  protected _loadingModel = new SelectionModel<I>(true);
  protected _loading = false;

  treeControl = new NestedTreeControl<T, I>((parent: T) => this.fetchChildren(parent), {
    trackBy: (node: T) => this.provider.toId(node),
  });

  rootPage: IDataPageModel;
  descendentPageMap: Map<I, IDataPageModel>;

  protected _nodes$ = new BehaviorSubject<IDataNode<T, I>[]>([]);
  protected _displayChange$ = new Subject<void>();

  constructor(
    protected readonly provider: IDataTreeProvider<T, F, A, I>,
    protected readonly options: IDataTreeViewOptions<T, F, A>,
    protected readonly operationService: OperationObserverService,
  ) {}

  /**
   * Subscribe to observables and return observable root nodes
   */
  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    return of([]);
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  refreshRootNodes() {}

  observeChildren(parent: IDataNode<T>) {
    return this._nodes$.pipe(map((nodes) => {}));
  }

  fetchChildren(parent: T) {
    const fetch = this.provider.fetchChildren({ parent });

    if (isObservable(fetch)) {
      return fetch.pipe(map((c) => c.items));
    }

    return fetch.items;
  }

  getActive() {
    return this._activeModel.selected[0] ?? null;
  }

  setActive(id: I) {
    this._activeModel.select(id);
    this._displayChange$.next();
  }

  clearActive() {
    this._activeModel.clear();
    this._displayChange$.next();
  }

  activeChanges() {
    return this._activeModel.changed;
  }

  private makeNode(data: T): IDataNode<T> {
    return {
      id: this.provider.toId(data),
      childCount: this.provider.getChildCount(data),
      parentId: this.provider.getParentId(data),
      data,
    };
  }
}
