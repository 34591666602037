import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms ease-out', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('100ms ease-in', style({ opacity: 0 }))]),
]);

export const menuInOut = trigger('menuInOut', [
  transition(':enter', [
    style({ opacity: 0, top: '0px', transform: 'scale(0.97)' }),
    animate('100ms ease-in-out', style({ opacity: 1, top: '48px', transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, top: '48px' }),
    animate('200ms ease-out', style({ opacity: 0, top: '0px' })),
  ]),
]);

export const expandInOut = (width: number) =>
  trigger(`expandInOut${width}`, [
    transition(':enter', [
      style({ width: 0 }),
      animate('300ms ease-out', style({ width: `${width}px` })),
    ]),
    transition(':leave', [
      style({ width: `${width}px` }),
      animate('300ms ease-in', style({ width: 0 })),
    ]),
  ]);

export const menuAccordian = trigger('menuAccordian', [
  state(
    '*',
    style({
      height: 0,
    }),
  ),
  state(
    'show',
    style({
      height: '*',
    }),
  ),
  transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
]);

export const slideInFromRight = trigger('slideInFromRight', [
  transition(':enter', [
    style({ transform: 'translateX(120%)', opacity: 0 }),
    animate('300ms ease-out', style({ transform: 'translateX(0%)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0%)', opacity: 1 }),
    animate('300ms ease-in', style({ transform: 'translateX(120%)', opacity: 0 })),
  ]),
]);
