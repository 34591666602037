import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatedError } from '@x/common/error';

@Component({
  selector: 'x-error-dialog',
  templateUrl: 'error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: TranslatedError,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
