import { AbstractControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DataCollectionView } from '../views/data-collection-view';

export interface DataDisplayControlOptions {
  modelViewTransform?: (modelValue: any) => any;
  viewModelTransform?: (modelValue: any) => any;
}

export class DataDisplayControl<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any,
> extends FormGroup<TControl> {
  private _lastValue: any = null;

  constructor(
    private view: DataCollectionView<any, any, any, any>,
    controls: TControl,
    private options?: DataDisplayControlOptions,
  ) {
    super(controls);

    const changes$ = this.valueChanges.pipe(
      tap((value) => {
        this._lastValue = options?.viewModelTransform ? options.viewModelTransform(value) : value;
        view.setDisplayOptions(this._lastValue);
      }),
    );

    const queryChange$ = view.queryChanges().pipe(
      tap(() => {
        this.resetValueFromModel();
      }),
    );

    this.resetValueFromModel();

    view.bindObservable(queryChange$);
    view.bindObservable(changes$);
  }

  private resetValueFromModel(emitEvent = false) {
    if (this.view.displayOptions === this._lastValue) return;

    const displayOptions = this.options?.modelViewTransform
      ? this.options.modelViewTransform(this.view.displayOptions)
      : this.view.displayOptions;

    const value = Object.entries(this.controls).reduce((value, [key, control]) => {
      value[key] = (<any>displayOptions)[key] ?? null;
      return value;
    }, {} as any);

    this.reset(value, { emitEvent });
  }
}
