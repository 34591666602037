export type FormErrorMap = { [key: string]: string | null };

export class FormErrorBag {
  constructor(private errors: FormErrorMap) {}

  get(key: string) {
    if (this.errors[key] === undefined || this.errors[key] === null) return null;

    return this.errors[key];
  }
}
