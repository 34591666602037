<div class="suggestions">
  <div
    class="suggestion selected"
    *ngIf="selected$ | async as suggestion"
    (click)="clearSelected()"
    matTooltip="Remove filter"
    matTooltipClass="accent"
  >
    <div class="labels">
      <div class="label-wrap">
        <span class="sub label mat-display-3">{{ suggestion.subLabel }}</span>
      </div>
    </div>

    <span class="icon-right">x</span>
  </div>
  <div class="suggestion" *ngFor="let suggestion of addresses" (click)="onSelected(suggestion)">
    <fa-icon class="icon-left" icon="map-pin"></fa-icon>
    <div class="labels">
      <div class="label-wrap">
        <span class="main label">{{ suggestion.label }}</span>
        <span class="size mat-display-1" *ngIf="suggestion.size">({{ suggestion.size }})</span>
      </div>
      <div class="label-wrap">
        <div class="mat-display-3 sub label">{{ suggestion.subLabel }}</div>
      </div>
    </div>
    <fa-icon
      class="icon-right"
      *ngIf="suggestion.type === 'container'"
      icon="chevron-right"
    ></fa-icon>
  </div>

  <ng-container *ngIf="addresses.length === 0">
    <ng-content select="[xSavedAddresses]"></ng-content>
  </ng-container>
</div>

<div class="loader" *ngIf="loaderTemplate && addresses.length">
  <ng-container *ngTemplateOutlet="loaderTemplate"></ng-container>
</div>
