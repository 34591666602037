import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Positioned

export const slider = trigger('routeAnimations', [
  transition('one => *', slideTo('right')),
  transition('* => one', slideTo('left')),
  transition('two => *', slideTo('right')),
  transition('* => two', slideTo('left')),
  transition('three => *', slideTo('right')),
  transition('* => three', slideTo('left')),
]);

function slideTo(direction: string) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional,
    ),
    query(':enter', [style({ [direction]: '-100%' })], optional),
    group([
      query(':leave', [animate('300ms ease-out', style({ [direction]: '100%' }))], optional),
      query(':enter', [animate('300ms ease-out', style({ [direction]: '0%' }))], optional),
      query(':leave', animateChild(), optional),
      query(':enter', animateChild(), optional),
    ]),

    // Required only if you have child animations on the page
  ];
}
