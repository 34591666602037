import { ApolloLink } from '@apollo/client/core';
import { Log } from '@x/common/log';

export function LoggerLinkFactory() {
  const log = Log.createSubnamespace('Graph');

  return new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const client = context.clientAwareness?.name ?? 'default';

    log.debug(`${operation.operationName} Request, client ${client}`, operation.variables);

    return forward(operation).map((result) => {
      log.debug(`${operation.operationName} Response`, result);
      return result;
    });
  });
}
