<ng-container
  [ngTemplateOutlet]="triggerTemplate?.template ?? defaultTriggerTemplate"
  [ngTemplateOutletContext]="{ $implicit: value }"
></ng-container>

<ng-container *ngIf="!(triggerTemplate?.template && value)">
  <input
    type="text"
    #input
    class="model-autocomplete-input"
    [placeholder]="_placeholder"
    [formControl]="control"
    [matAutocomplete]="modelAutocomplete"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    [id]="id + '-input'"
    autocomplete="model-autocomplete"
  />
</ng-container>

<fa-icon class="clear-icon" icon="times-circle" *ngIf="!empty" (click)="resetSelected()"></fa-icon>
<mat-autocomplete
  #modelAutocomplete="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onOptionSelected($event)"
>
  <mat-option
    *ngFor="let option of filteredOptions$ | async; trackBy: trackFn ?? defaultTrackFn"
    [value]="option"
  >
    <ng-container
      [ngTemplateOutlet]="optionTemplate?.template ?? defaultOptionTemplate"
      [ngTemplateOutletContext]="{ $implicit: option }"
    ></ng-container>
  </mat-option>
</mat-autocomplete>

<ng-template #defaultOptionTemplate let-option>
  <span>{{ displayWith(option) }}</span>
</ng-template>

<ng-template #defaultTriggerTemplate></ng-template>
