import { Directive, EventEmitter, Host, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroupName,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { FormErrorBag, FormErrorMap, FormHelperService } from '@x/common/form';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[xFormErrorBoundary]',
})
export class FormErrorBoundaryDirective implements OnInit, OnDestroy {
  emptyErrorMap: FormErrorMap = {};
  errors: FormErrorBag = new FormErrorBag({});
  errorChanges = new EventEmitter<FormErrorBag>();

  private _destroy$ = new Subject<void>();

  constructor(
    private formHelper: FormHelperService,
    @Host()
    private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    let group: UntypedFormGroup | UntypedFormArray;

    if (
      this.controlContainer instanceof FormGroupDirective ||
      this.controlContainer instanceof FormGroupName
    ) {
      group = this.controlContainer.control;
    } else {
      console.warn(
        'FormErrorBoundaryDirective: Unsupported ControlContainer type injected',
        this.controlContainer,
      );
      return;
    }

    group.statusChanges
      .pipe(
        startWith(group.status),
        map((status) => {
          // console.log('FormErrorBoundaryDirective: status changed', status);
          if (status === 'INVALID') {
            return this.formHelper.extractErrorMessages(group);
          }

          return this.emptyErrorMap;
        }),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe((map) => {
        // console.log('FormErrorBoundaryDirective: emit', map);
        this.errors = new FormErrorBag(map);
        this.errorChanges.emit(this.errors);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
