<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <input
    type="number"
    matInput
    [formControl]="control"
    [placeholder]="data.placeholder ?? ''"
    [min]="data.min ?? null"
    [max]="data.max ?? null"
    [required]="data.required ?? false"
  />
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{ assign: false, value: control.value }">
    {{ data.cancelLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="control.invalid"
    [mat-dialog-close]="{ assign: true, value: control.value }"
  >
    {{ data.okLabel }}
  </button>
</div>
