import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { DatetimeFormatter } from '../datetime-formatter';

@Pipe({
  name: 'datetime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private readonly dateTimeFormat: DatetimeFormatter) {}
  transform(value: string | Date | DateTime, format?: string, timezone?: string) {
    return this.dateTimeFormat.toFormat(value, format, timezone);
  }
}
