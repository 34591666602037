import { Inject, Injectable, InjectionToken } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { createRange } from '@x/common/utils';
import { DateTime, DateTimeOptions, Info, ToISOTimeOptions } from 'luxon';
import { MatLuxonDateAdapterOptions } from 'ngx-material-luxon';

/**
 * - `date` ISO Date format (e.g `1982-05-25`)
 * - `datetime` ISO Date format (e.g `1982-05-25T00:00:00.000Z`)
 */
export type StringDateAdapterFormat = 'date' | 'datetime';

/**
 * Configuration for a `StringDateAdapter` instance
 */
export interface IStringDateAdapterConfig {
  /**
   * Set the locale.
   *
   * Defaults to whatever was specified in config via `STRING_DATE_ADAPTER_OPTIONS`
   */
  locale?: string;

  /**
   * Set the timezone.
   *
   * Defaults to whatever was specified in config via `STRING_DATE_ADAPTER_OPTIONS`
   */
  timezone?: string;

  /**
   * Set a format for the value. (`datetime` by default)
   *
   * @see StringDateAdapterFormat
   */
  format?: StringDateAdapterFormat;

  /**
   * Specify additional ISO configuration for Luxon
   *
   * @see Luxon.ToISOTimeOptions
   */
  iso?: ToISOTimeOptions;
}

/**
 * Options for the `STRING_DATE_ADAPTER_OPTIONS` injection token.
 *
 * Used to provide defaults for the `StringDateAdapter`
 */
export interface IStringDateAdapterOptions extends MatLuxonDateAdapterOptions {
  /**
   * A default timezone to be used by the `StringDateAdapter`
   */
  defaultTimezone: string;

  /**
   * A default locale to be used by the `StringDateAdapter`
   */
  defaultLocale?: string;

  /**
   * Default ISO configuration for Luxon to be used by the `StringDateAdapter`
   */
  defaultIsoOptions?: ToISOTimeOptions;

  /**
   * Default format for the `StringDateAdapter` value.
   *
   * @see StringDateAdapterFormat
   */
  defaultFormat?: StringDateAdapterFormat;
}

export const STRING_DATE_ADAPTER_OPTIONS = new InjectionToken<IStringDateAdapterOptions>(
  'STRING_DATE_ADAPTER_OPTIONS',
);

export class StringDateAdapterException extends Error {}

@Injectable()
export class StringDateAdapter extends DateAdapter<string> {
  private _timezone?: string;
  private _format: StringDateAdapterFormat = 'date';
  private _isoOptions?: ToISOTimeOptions;

  constructor(
    @Inject(STRING_DATE_ADAPTER_OPTIONS)
    readonly options: IStringDateAdapterOptions,
  ) {
    super();
    this.configure({
      timezone: options.defaultTimezone,
      locale: options.defaultLocale,
      format: options.defaultFormat,
      iso: options.defaultIsoOptions,
    });
  }

  configure({ timezone, locale, format, iso }: IStringDateAdapterConfig) {
    if (timezone) this.setTimezone(timezone);
    if (locale) this.setLocale(locale);
    if (format) this.setFormat(format);
    if (iso) this.setIsoOptions(iso);
  }

  get timezone() {
    return this._timezone;
  }

  setTimezone(timezone: string): void {
    this._timezone = timezone;
  }

  setFormat(format: StringDateAdapterFormat): void {
    this._format = format;
  }

  setIsoOptions(iso: ToISOTimeOptions): void {
    this._isoOptions = iso;
  }

  getYear(date: string): number {
    return this._stringToDateTime(date).year;
  }

  getMonth(date: string): number {
    // Luxon works with 1-indexed months whereas our code expects 0-indexed.
    return this._stringToDateTime(date).month - 1;
  }

  getDate(date: string): number {
    return this._stringToDateTime(date).day;
  }

  getDayOfWeek(date: string): number {
    return this._stringToDateTime(date).weekday;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // as per LuxonDateAdapter
    return Info.months(style, { locale: this.locale });
  }

  getDateNames(): string[] {
    // as per LuxonDateAdapter
    const dtf = new Intl.DateTimeFormat(this.locale, { day: 'numeric', timeZone: 'utc' });
    return createRange(31, (i) =>
      dtf.format(DateTime.utc(2017, 1, i + 1, this._getDateTimeOptions()).toJSDate()),
    );
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    // as per LuxonDateAdapter
    const days = Info.weekdays(style, { locale: this.locale });
    days.unshift(days.pop()!);
    return days;
  }

  getYearName(date: string): string {
    // as per LuxonDateAdapter
    return this._stringToDateTime(date).toFormat('yyyy');
  }

  getFirstDayOfWeek(): number {
    // as per LuxonDateAdapter
    // 0 = Sunday, 1 = Monday
    return this.options?.firstDayOfWeek?.(this.locale) ?? 0;
  }

  getNumDaysInMonth(date: string): number {
    return this._stringToDateTime(date).daysInMonth ?? 0;
  }

  clone(date: string): string {
    return date; // 🤯
  }

  createDate(year: number, month: number, date: number): string {
    if (month < 0 || month > 11) {
      throw new StringDateAdapterException(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw new StringDateAdapterException(
        `Invalid date "${date}". Date has to be greater than 0.`,
      );
    }

    // Luxon uses 1-indexed months so we need to add one to the month.
    const result = this.options?.useUtc
      ? DateTime.utc(year, month + 1, date, this._getDateTimeOptions())
      : DateTime.local(year, month + 1, date, {
          ...this._getDateTimeOptions(),
          zone: this._timezone,
        });

    // if (result.isValid) {
    //   throw StringDateAdapterException(`Invalid date "${date}". Reason: "${result.invalidReason}".`);
    // }

    return this._dateTimeToString(result);
  }

  today(): string {
    return this._dateTimeToString(
      this.options?.useUtc
        ? DateTime.utc()
        : DateTime.local({
            ...this._getDateTimeOptions(),
            zone: this._timezone,
          }),
    );
  }

  parse(value: any, parseFormat: string | string[]): string | null {
    if (typeof value == 'string' && value.length > 0) {
      const iso8601Date = this._stringToDateTime(value, false);

      if (iso8601Date.isValid) {
        return this._dateTimeToString(iso8601Date);
      }

      const formats = Array.isArray(parseFormat) ? parseFormat : [parseFormat];

      if (!formats.length) {
        throw new StringDateAdapterException('Formats array must not be empty.');
      }

      for (const format of formats) {
        const fromFormat = DateTime.fromFormat(value, format);

        if (fromFormat.isValid) {
          return this._dateTimeToString(fromFormat);
        }
      }

      return this.invalid();
    } else if (typeof value === 'number') {
      return this._dateTimeToString(DateTime.fromMillis(value, this._getDateTimeOptions()));
    } else if (value instanceof Date) {
      return this._dateTimeToString(DateTime.fromJSDate(value, this._getDateTimeOptions()));
    } else if (value instanceof DateTime) {
      return this._dateTimeToString(
        DateTime.fromMillis(value.toMillis(), this._getDateTimeOptions()),
        'datetime',
      );
    }

    return null;
  }

  format(date: string, displayFormat: any): string {
    return this._stringToDateTime(date).toFormat(displayFormat);
  }

  addCalendarYears(date: string, years: number): string {
    return this._dateTimeToString(this._stringToDateTime(date).plus({ years }));
  }

  addCalendarMonths(date: string, months: number): string {
    return this._dateTimeToString(this._stringToDateTime(date).plus({ months }));
  }

  addCalendarDays(date: string, days: number): string {
    return this._dateTimeToString(this._stringToDateTime(date).plus({ days }));
  }

  toIso8601(date: string): string {
    return this._dateTimeToString(this._stringToDateTime(date));
  }

  isDateInstance(obj: any): boolean {
    if (typeof obj === 'string') {
      return this._stringToDateTime(obj, false).isValid;
    }
    return false;
  }

  isValid(date: string): boolean {
    return this._stringToDateTime(date, false).isValid;
  }

  invalid(): string {
    return '';
  }

  private _stringToDateTime(value: string, StringDateAdapterExceptionOnInvalid = true): DateTime {
    const date = DateTime.fromISO(value, {
      ...this._getDateTimeOptions(),
      zone: this._timezone,
    });
    if (!date.isValid && StringDateAdapterExceptionOnInvalid) {
      throw new StringDateAdapterException(`Invalid date "${date}". Reason: ${date.invalidReason}`);
    }
    return date;
  }

  private _dateTimeToString(value: DateTime, overrideFormat?: StringDateAdapterFormat): string {
    const format = overrideFormat ?? this._format;

    switch (format) {
      case 'date':
        return String(value.setZone(this._timezone).toISODate(this._isoOptions));
      case 'datetime':
        return String(value.setZone(this._timezone).toISO(this._isoOptions));
    }
  }

  /** Gets the options that should be used when constructing a new `DateTime` object. */
  private _getDateTimeOptions(): DateTimeOptions {
    return {
      locale: this.locale,
      outputCalendar: 'gregory',
    };
  }
}
