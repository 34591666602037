import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ModelAutocompleteOptionDefDirective } from './model-autocomplete-option-def.directive';
import { ModelAutocompleteTriggerDefDirective } from './model-autocomplete-trigger-def.directive';
import { ModelAutocompleteDatasource } from './model-autocomplete.datasource';
import { ModelTransformer } from './model.transformer';

@Component({
  selector: 'x-model-autocomplete-chips',
  templateUrl: './model-autocomplete-chips.component.html',
  styleUrls: ['./model-autocomplete-chips.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ModelAutocompleteChipsComponent }],
})
export class ModelAutocompleteChipsComponent
  implements OnInit, OnDestroy, DoCheck, MatFormFieldControl<any>, ControlValueAccessor
{
  static nextId = 0;

  @HostBinding()
  id = `x-model-autocomplete-chips-${ModelAutocompleteChipsComponent.nextId++}`;

  controlType = 'x-model-autocomplete-chips';

  @ContentChild(ModelAutocompleteOptionDefDirective)
  optionTemplate?: ModelAutocompleteOptionDefDirective;

  @ContentChild(ModelAutocompleteTriggerDefDirective)
  triggerTemplate?: ModelAutocompleteTriggerDefDirective;

  @ViewChild(MatAutocomplete)
  autocomplete: MatAutocomplete;

  @ViewChild('input')
  inputRef: ElementRef<HTMLInputElement>;

  @Input()
  get value(): any[] | null {
    return this._value;
  }
  set value(value: any[] | null) {
    this.writeValue(value);
  }
  _value: any[] | null = [];

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  displayWith = (option: any | null | undefined) => {
    return option === null || option === undefined ? '' : String(option);
  };

  @Input()
  dataSource: ModelAutocompleteDatasource;

  @Input()
  transformer?: ModelTransformer;

  @Input()
  debounceTime = 300;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @HostBinding('class.floating')
  shouldLabelFloat: boolean;

  @Input('aria-describedby')
  ariaDescribedby: string;

  @Input()
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @Input()
  removable = true;

  get empty() {
    return !this._value || (Array.isArray(this._value) && this._value.length === 0);
  }

  /**
   * See https://material.angular.io/guide/creating-a-custom-form-field-control#statechanges
   */
  stateChanges = new Subject<void>();

  /**
   * See https://material.angular.io/guide/creating-a-custom-form-field-control#focused
   */
  focused = false;

  control = new UntypedFormControl(null);

  filteredOptions$ = new BehaviorSubject<any[]>([]);

  touched = false;
  onChange: any = () => {};
  onTouch: any = () => {};
  errorState = false;
  objects: any[] = [];

  defaultTrackFn: TrackByFunction<any> = (i: number, item: any) => {
    return item;
  };

  trackFn?: TrackByFunction<any>;

  private dataSourceSub: Subscription;

  constructor(
    private _elementRef: ElementRef,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    private _changeRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() private _parentFormField: MatFormField,
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (!this.dataSource) {
      console.warn(
        'ModelAutocompleteComponent: [dataSource] must be an instance of ModelAutocompleteDatasource',
      );
      return;
    }
    const textChanges$ = this.control.valueChanges.pipe(
      debounceTime(this.debounceTime),
      filter((value) => typeof value === 'string' && value.length > 0),
    );
    this.trackFn = this.dataSource.trackByFn;
    this.dataSourceSub = this.dataSource
      .connect(textChanges$)
      .pipe(
        tap((options) => {
          this.filteredOptions$.next(options);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.dataSourceSub) {
      this.dataSourceSub.unsubscribe();
    }
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.stateChanges.complete();
    this.filteredOptions$.complete();
  }

  ngDoCheck() {
    if (this.ngControl) this.updateErrorState();
    if (!this._parentFormField) {
      this.shouldLabelFloat = true;
    } else {
      this.shouldLabelFloat =
        this.focused || !this.empty || (this.autocomplete && this.autocomplete.isOpen);
    }
  }

  writeValue(value: any): void {
    if (this._value === value) return;

    this._value = value;
    this.stateChanges.next();

    if (value === null || value === undefined) {
      this.objects = [];
    } else if (Array.isArray(value)) {
      this.transformValueToObjects(value).then((o) => {
        this.objects = o;
        this.stateChanges.next();
      });
    } else {
      throw new Error(`Input value must be an array or null, got '${value}'`);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.inputRef.nativeElement.select();
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.inputRef.nativeElement.value = '';
      this.touched = true;
      this.focused = false;
      this.onTouch();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.model-autocomplete-input',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  /**
   * View -> Model Change
   * @param event MatAutocompleteSelectedEvent
   */
  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.inputRef.nativeElement.value = '';

    let value = this.transformModelToValue(event.option.value);

    this._value = this._value ? [...this._value, value] : [value];
    this.objects = [...this.objects, event.option.value];

    this.onChange(this._value);
    this.stateChanges.next();
  }

  /**
   * View -> Model change
   */
  resetSelected() {
    this._value = null;
    this.onChange(null);
    this.stateChanges.next();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
  }

  onOptionRemove(model: any): void {
    let value = this.transformModelToValue(model);

    if (Array.isArray(this._value)) {
      let index = this._value.indexOf(value);

      this._value = this._value.filter((v, i) => i !== index);
      this.objects = this.objects.filter((v, i) => i !== index);
    } else {
      this._value = [];
      this.objects = [];
    }
    this.onChange(this._value);
    this.stateChanges.next();
  }

  private async transformValueToObjects(value: any[]): Promise<any[]> {
    if (this.transformer) {
      let objects = await Promise.all(
        value.map(async (v) => {
          if (this.transformer) {
            return this.transformer.valueToModel(v);
          }
          return v;
        }),
      );

      return objects;
    }

    return value;
  }

  private transformModelToValue(model: any) {
    if (this.transformer) {
      return this.transformer.modelToValue(model);
    }
    return model;
  }

  private updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = /*this.errorStateMatcher || */ this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as UntypedFormControl) : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
