import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[xField]',
  host: {
    class: 'x-field',
  },
})
export class FieldDirective {
  @Input('xField')
  set width(width: number | string | undefined) {
    this._width = coerceNumberProperty(width, 12);
  }

  @HostBinding('class')
  get class() {
    return `col-${this._width}`;
  }

  private _width: number = 12;
}
