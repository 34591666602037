import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IDataCollection, IDataPageModel, IDataSortModel } from '../types/data-model';

export const DATA_TABLE_VIEW_STORAGE = new InjectionToken<IDataTableViewStorage>(
  'DATA_TABLE_VIEW_STORAGE',
);

export interface IDataTableViewState<T = any, F = any, A = any, I = string | number> {
  id: string;
  title?: string | null;
  description?: string | null;
  position?: number | null;
  visibleColumns?: string[] | null;
  sort?: IDataSortModel | null;
  filter?: F | null;
  searchText?: string | null;
  args?: A | null;
  data?: IDataCollection<T> | null;
  page?: IDataPageModel | null;
  selected?: I[] | null;
  active?: I | null;
  displayOptions?: any;
}

export interface IDataTableViewStorage<T = any, F = any, A = any, I = string | number> {
  saveCurrentState(repository: string, state: IDataTableViewState<T, F, A, I>): void;
  restoreCurrentState(repository: string): IDataTableViewState<T, F, A, I> | null;

  watch(repository: string): Observable<IDataTableViewState<T, F, A, I>[]>;
  create(
    repository: string,
    state: Omit<IDataTableViewState<T, F, A, I>, 'id'>,
  ): Observable<IDataTableViewState<T, F, A, I>>;
  update(
    repository: string,
    state: IDataTableViewState<T, F, A, I>,
  ): Observable<IDataTableViewState<T, F, A, I>>;
}
