import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControlStatus,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'x-form-status-label',
  templateUrl: 'form-status-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-form-status-label',
  },
})
export class FormStatusLabelComponent implements OnInit {
  @Input()
  control: UntypedFormControl | UntypedFormGroup | UntypedFormArray | undefined | null;

  statusMessage: string;
  status: FormControlStatus | 'UNTOUCHED' | 'UNINITIALIZED' = 'UNINITIALIZED';

  @HostBinding('class.status-invalid')
  get isStatusInvalid() {
    return this.status === 'INVALID';
  }

  @HostBinding('class.status-valid')
  get isStatusValid() {
    return this.status === 'VALID';
  }

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control?.statusChanges.subscribe((status) => {
      this.updateStatus();
      this.changeRef.markForCheck();
    });
    this.updateStatus();
  }

  updateStatus() {
    console.log('status', this.control?.status, this.control?.touched);

    if (!this.control) {
      this.statusMessage = 'Form Uninitialized';
      this.status = 'UNINITIALIZED';
      return;
    }

    if (!this.control.dirty) {
      this.status = 'UNTOUCHED';
      this.statusMessage = 'Form Unchanged';
      return;
    }

    this.status = this.control?.status;

    switch (this.control.status) {
      case 'VALID':
        this.statusMessage = 'Form Valid';
        break;
      case 'INVALID':
        this.statusMessage = 'Form Invalid';
        break;
      case 'PENDING':
        this.statusMessage = 'Form Validating';
        break;
      case 'DISABLED':
        this.statusMessage = 'Form Disabled';
        break;
    }
  }
}
