import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SHOP_FORM_ERROR_TRANSLATIONS } from '@x/common/form/services/form-error-translations';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { FormErrorBag, FormErrorMap } from '../form-error-bag';

export const EMPTY_ERROR_BAG = new FormErrorBag(Object.freeze({}));

@Injectable()
export class FormHelperService {
  extractErrorMessage(group: UntypedFormGroup, path: string): string | null {
    let control = group.get(path);

    if (control === null) {
      console.warn(
        `FormHelperService: Control '${path}' does not exist on FormGroup. Controls: %o`,
        Object.keys(group.controls),
      );
      return null;
    }

    return this.getControlErrorMessage(control, path);
  }

  extractErrorMessages(
    group: UntypedFormGroup | UntypedFormArray,
    errors: FormErrorMap = {},
    prefix?: string,
  ): FormErrorMap {
    let keys =
      group instanceof UntypedFormGroup ? Object.keys(group.controls) : group.controls.keys();

    for (const key of keys) {
      const control = group.get(<string>key);

      if (!control) continue;

      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.extractErrorMessages(control, errors, prefix ? `${prefix}.${key}` : String(key));
      } else if (control.invalid) {
        const errorKey: string = prefix ? `${prefix}.${key}` : `${key}`;
        errors[errorKey] = this.getControlErrorMessage(control, errorKey);
      }
    }

    return errors;
  }

  watchErrors(group: UntypedFormGroup): Observable<FormErrorBag> {
    return group.statusChanges.pipe(
      startWith(null),
      map((status) => {
        if (status === 'INVALID') {
          return new FormErrorBag(this.extractErrorMessages(group));
        }

        return EMPTY_ERROR_BAG;
      }),
      distinctUntilChanged(),
    );
  }

  getControlErrorMessage(control: AbstractControl, controlName: string): string | null {
    if (control.errors === null) return null;

    let errors = control.errors;

    return Object.keys(errors)
      .map((key) => {
        if (typeof errors[key] === 'object') {
          if (key === 'minlength') {
            return SHOP_FORM_ERROR_TRANSLATIONS.minlength(errors[key].requiredLength);
          }

          if (key === 'maxlength') {
            return SHOP_FORM_ERROR_TRANSLATIONS.maxlength(errors[key].requiredLength);
          }

          let attributes = Object.keys(errors[key]).map((k) => `${k}=${errors[key][k]}`);
          return `Invalid [${key} ${attributes}]`;
        }

        return SHOP_FORM_ERROR_TRANSLATIONS[key]
          ? SHOP_FORM_ERROR_TRANSLATIONS[key]
          : `Invalid [${key}]`;
      })
      .join(', ');
  }
}
