import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { AddressAutocompleteComponent } from '@x/common/form/components/address-autocomplete/address-autocomplete.component';
import { AddressLoaderDirective } from '@x/common/form/components/address-autocomplete/address-loader.directive';
import { AddressSuggestionsOverlayComponent } from '@x/common/form/components/address-autocomplete/address-suggestions-overlay.component';
import { AddressCoordinatesInputComponent } from '@x/common/form/components/address-coordinates-input/address-coordinates-input.component';
import { ProvinceAutocompleteComponent } from '@x/common/form/components/province-autocomplete/province-autocomplete.component';
import { IconModule } from '@x/common/icon';
import { CountryAutocompleteComponent } from './components/country-autocomplete/country-autocomplete.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { TelInputControlComponent } from './components/tel-input-control/tel-input-control.component';
import { FieldDirective } from './directives/field.directive';
import { FormErrorBoundaryDirective } from './directives/form-error-boundary.directive';
import { FormErrorDirective } from './directives/form-error.directive';
import { OnlyNumberPatternDirective } from './directives/only-number-pattern.directive';
import { OnlyPositiveNumberDirective } from './directives/only-positive-number.directive';
import { TrimInputDirective } from './directives/trim-input.directive';
import { FormErrorsPipe } from './pipes/form-errors.pipe';
import { FormHelperService } from './services/form-helper.service';

@NgModule({
  providers: [FormHelperService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    GoogleMapsModule,
    IconModule.forFeature([faEyeSlash, faEye]),
    MatAutocompleteModule,
    MatTooltipModule,
    CdkConnectedOverlay,
  ],
  declarations: [
    FormErrorsPipe,
    FieldDirective,
    FormErrorDirective,
    FormErrorBoundaryDirective,
    OnlyPositiveNumberDirective,
    TelInputControlComponent,
    AddressCoordinatesInputComponent,
    PasswordInputComponent,
    OnlyNumberPatternDirective,
    AddressAutocompleteComponent,
    AddressLoaderDirective,
    AddressSuggestionsOverlayComponent,
    ProvinceAutocompleteComponent,
    CountryAutocompleteComponent,
    TrimInputDirective,
  ],
  exports: [
    FormErrorsPipe,
    FieldDirective,
    FormErrorDirective,
    FormErrorBoundaryDirective,
    OnlyPositiveNumberDirective,
    TelInputControlComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    AddressCoordinatesInputComponent,
    PasswordInputComponent,
    OnlyNumberPatternDirective,
    AddressAutocompleteComponent,
    AddressLoaderDirective,
    ProvinceAutocompleteComponent,
    CountryAutocompleteComponent,
    TrimInputDirective,
  ],
})
export class FormCommonModule {}
