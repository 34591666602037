import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { CommonValidator } from '@x/common/form';
import { CountryService, ICountryItem, IProvinceItem } from '@x/geocode/client';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'x-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CountryAutocompleteComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryAutocompleteComponent
  implements MatFormFieldControl<any>, OnInit, OnDestroy, DoCheck, ControlValueAccessor
{
  searching$ = new BehaviorSubject<boolean>(false);
  countrySearchControl = new FormControl<string | ICountryItem>('', {
    nonNullable: true,
    validators: CommonValidator.valueObjectHasKey('code'),
  });

  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  autofilled = false;
  controlType = 'country-autocomplete';
  id = `country-autocomplete-${CountryAutocompleteComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const { value } = this.countrySearchControl;

    return !value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = 'Search and select country...';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.countrySearchControl.disable() : this.countrySearchControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string {
    if (this.countrySearchControl.valid) {
      const { value } = this.countrySearchControl;
      return typeof value === 'string' ? value : value.code;
    }
    return '';
  }
  set value(search: string | ICountryItem) {
    this.countrySearchControl.patchValue(search);
    this.stateChanges.next();
  }

  errorState = false;

  private countrySearchControlValueChanges$ = this.countrySearchControl.valueChanges.pipe(
    tap(() => this.searching$.next(true)),
    startWith(this.value),
    debounceTime(300),
    distinctUntilChanged(),
  );

  filteredOptions$: Observable<IProvinceItem[]> = this.countrySearchControlValueChanges$.pipe(
    switchMap((value) => this.fetchCountry$(value)),
    tap(() => this.searching$.next(false)),
    shareReplay(),
  );

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() public parentFormField: MatFormField,
    private countryService: CountryService,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.country-autocomplete-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector<HTMLInputElement>('input')?.focus();
    }
  }

  async writeValue(code: string | null) {
    if (!code) return;

    try {
      this.value = await firstValueFrom(this.countryService.fetchCountryByCode(code));
    } catch (e) {
      this.value = '';
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateErrorState() {
    const oldState = this.errorState;
    const parentFormGroup = this._parentFormGroup || this._parentForm;
    const matcher = this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;
    const newState = matcher.isErrorState(control, parentFormGroup);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.ngControl) this.updateErrorState();
  }

  private fetchCountry$(value: string | ICountryItem): Observable<ICountryItem[]> {
    if (typeof value !== 'string') return of([]);

    return this.countryService.fetchCountrySearch(value).pipe(catchError(() => of([])));
  }

  optionSelected($event: MatAutocompleteSelectedEvent) {
    const country: ICountryItem | null = $event.option.value;

    if (country) {
      this.onChange(country.code);
      this.onTouched();
    }
  }

  displayFn(country: ICountryItem): string {
    return country?.name ?? '';
  }

  autoCompleteClosed() {
    if (this.countrySearchControl.invalid) {
      this.onChange(null);
      this.value = '';
      this.searching$.next(false);
    }
  }
}
