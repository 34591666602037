<mat-form-field class="w-100" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    [type]="hide ? 'text' : 'password'"
    matInput
    placeholder="Password..."
    [formControl]="password"
  />
  <fa-icon
    [icon]="hide ? ['fas', 'eye'] : ['fas', 'eye-slash']"
    matSuffix
    (click)="hide = !hide"
  ></fa-icon>

  <mat-error xFormError="password"></mat-error>
</mat-form-field>
