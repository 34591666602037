import { ModuleWithProviders, NgModule } from '@angular/core';
import { GRAPH_CLIENT } from '@x/common/graph';
import { GeocodeClientModuleConfig } from './geocode-client.config';
import { CountryService } from './services/country.service';
import { GeocodeService } from './services/geocode.service';

@NgModule({})
export class GeocodeClientModule {
  static forRoot(config: GeocodeClientModuleConfig): ModuleWithProviders<GeocodeClientModule> {
    return {
      ngModule: GeocodeClientModule,
      providers: [
        {
          provide: GRAPH_CLIENT,
          useValue: {
            name: 'geocode',
            uri: config.graphUrl ?? '/geocode/graphql',
            defaultOptions: {
              query: { fetchPolicy: 'no-cache', errorPolicy: 'none', returnPartialData: false },
            },
          },
          multi: true,
        },
        CountryService,
        GeocodeService,
      ],
    };
  }
}
