import { AbstractControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DataCollectionView } from '../views/data-collection-view';

export interface DataArgsControlOptions<M = any, V = any> {
  modelViewTransform?: (modelValue: M) => V;
  viewModelTransform?: (viewValue: V) => M;
}

export class DataArgsControl<
  M = any,
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any,
> extends FormGroup<TControl> {
  private _lastValue: any = null;

  constructor(
    private view: DataCollectionView<any, any, M, any>,
    controls: TControl,
    private options?: DataArgsControlOptions<M, FormGroup<TControl>['value']>,
  ) {
    super(controls);

    const changes$ = this.valueChanges.pipe(
      tap((value) => {
        this._lastValue = options?.viewModelTransform ? options.viewModelTransform(value) : value;
        view.setArgs(this._lastValue);
      }),
    );

    const queryChange$ = view.queryChanges().pipe(
      tap(() => {
        this.resetValueFromCurrentFilter();
      }),
    );

    this.resetValueFromCurrentFilter();

    view.bindObservable(queryChange$);
    view.bindObservable(changes$);
  }

  private resetValueFromCurrentFilter(emitEvent = false) {
    if (this.view.args === this._lastValue) return;

    const args = this.options?.modelViewTransform
      ? this.options.modelViewTransform(this.view.args)
      : this.view.args;

    const value = Object.entries(this.controls).reduce((value, [key, control]) => {
      value[key] = (<any>args)[key] ?? null;
      return value;
    }, {} as any);

    this.reset(value, { emitEvent });
  }
}
