import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { fadeInOut } from '@x/common/animations';
import { IAutocompleteAddressSuggestion } from '@x/geocode/client';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'x-address-suggestions-overlay',
  templateUrl: './address-suggestions-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
  host: {
    class: 'x-address-suggestions-overlay',
  },
})
export class AddressSuggestionsOverlayComponent {
  @HostBinding('@fadeInOut')
  fadeInOut: string;

  @Input() loaderTemplate: TemplateRef<any> | null = null;

  selected$ = new BehaviorSubject<IAutocompleteAddressSuggestion | null>(null);

  @Input() addresses: IAutocompleteAddressSuggestion[] = [];

  @Output() selectedSuggestion = new EventEmitter<IAutocompleteAddressSuggestion | null>();
  @Output() selectedAddress = new EventEmitter<string>();

  onSelected(suggestion: IAutocompleteAddressSuggestion) {
    this.selectedSuggestion.emit(suggestion);
    this.selected$.next(suggestion.type === 'container' ? suggestion : null);
  }

  clearSelected() {
    this.selected$.next(null);
    this.selectedSuggestion.emit(null);
  }
}
