import { DatePipe, DatePipeConfig, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import { coerceDateTime } from '../../utils/datetime';

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  datePipe: DatePipe;
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DATE_PIPE_DEFAULT_OPTIONS) @Optional() private defaultOptions?: DatePipeConfig | null,
  ) {
    this.datePipe = new DatePipe(locale, defaultOptions?.timezone, defaultOptions);
  }

  transform(
    range: [Date | string | number | null | undefined, Date | string | number | null | undefined],
    format?: string,
    timezone?: string,
  ) {
    const start = range[0];
    const end = range[1];

    if (!start && !end) {
      return null;
    }

    if (start && end) {
      return `${this.format(start, format, timezone)} - ${this.format(end, format, timezone)}`;
    } else if (start) {
      return `After ${this.format(start, format, timezone)}`;
    } else {
      return `Before ${this.format(end, format, timezone)}`;
    }
  }

  format(value: Date | string | number | null | undefined, format?: string, timezone?: string) {
    let datetime = coerceDateTime(value, timezone ? { zone: timezone } : undefined);

    if (!datetime) return datetime;

    return format ? datetime.toFormat(format) : datetime.toLocal();
  }
}
