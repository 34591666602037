import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Time } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { coerceTimeValue } from '@x/common/utils/datetime';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { StringDateAdapter } from '../../adapters/string.date-adapter';

@Component({
  selector: 'x-time-input-control',
  templateUrl: 'time-input.component.html',
  styleUrls: ['time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimeInputComponent,
    },
  ],
})
export class TimeInputComponent
  implements OnInit, OnDestroy, MatFormFieldControl<Time | null>, ControlValueAccessor
{
  // static - - - - - - - -

  static nextId = 0;

  // properties - - - - - -

  private _destroy$ = new Subject<void>();

  dateAdapter: StringDateAdapter;

  controlType = 'x-time-input-control';

  get id(): string {
    return `${this.controlType}-${TimeInputComponent.nextId++}`;
  }

  @ViewChild(MatInput, { static: true })
  timeInput: MatInput;
  timeControl = new FormControl<string | null>(null);

  stateChanges = new Subject<void>();

  placeholder: string = '';

  get focused(): boolean {
    return this.timeInput.focused;
  }

  get empty(): boolean {
    return this.value === null;
  }

  @HostBinding('class.floating')
  shouldLabelFloat = true;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  set value(time: Time | null) {
    this.writeValue(time);
  }
  get value(): Time | null {
    return this._value;
  }
  private _value: Time | null = null;

  errorState: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  // constructor - - - - - - -

  constructor(
    readonly _dateAdapter: DateAdapter<string>,
    private readonly changeRef: ChangeDetectorRef,
    @Optional() @Self() public readonly ngControl: NgControl,
  ) {
    this.dateAdapter = _dateAdapter as StringDateAdapter;
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  writeValue(obj: any): void {
    const time = coerceTimeValue(obj);
    this._value = time;
    this.updateModel();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.timeControl.valueChanges
      .pipe(takeUntil(this._destroy$), debounceTime(150))
      .subscribe((value) => {
        this._value = coerceTimeValue(value);
        this.onTouched();
        this.onChange(this._value);
        this.changeRef.markForCheck();
        this.stateChanges.next();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setDescribedByIds(ids: string[]) {}

  onContainerClick(event: MouseEvent): void {
    this.timeInput.focus();
  }

  private updateModel() {
    const value = this._value
      ? `${this._value.hours.toString().padStart(2, '0')}:${this._value.minutes
          .toString()
          .padStart(2, '0')}`
      : null;
    this.timeControl.setValue(value);
  }
}
