<div
  role="group"
  class="country-autocomplete-container"
  [attr.aria-labelledby]="_formField.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <input
    type="text"
    [placeholder]="placeholder"
    #input
    matInput
    [formControl]="countrySearchControl"
    [matAutocomplete]="auto"
  />

  <div class="loader" *ngIf="searching$ | async">
    <ng-content select="[xSearchLoader]"></ng-content>
  </div>

  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="optionSelected($event)"
    (closed)="autoCompleteClosed()"
  >
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</div>
