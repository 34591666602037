import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import {
  DataSelectOptionObject,
  DataSelectView,
  DataViewFactoryService,
  IDataCollectionProvider,
  IDataPageModel,
  IDataProvider,
  IDataSortModel,
} from '@x/common/data';
import { Operation } from '@x/common/operation';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[xDataSelectOptionDef]',
})
export class DataSelectOptionDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

export class DataChangeEvent<T = any> {
  constructor(public options: Array<DataSelectOptionObject<T>>) {}
}

export class SelectionChangeEvent<I = string | number> {
  constructor(public optionIds: Array<I> | I | null) {}
}

@Component({
  selector: 'x-data-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-select.component.html',
  styleUrls: ['data-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DataSelectComponent }],
  host: {
    class: 'x-data-select',
  },
})
export class DataSelectComponent<T = any, F = any, A = any>
  implements ControlValueAccessor, MatFormFieldControl<T>, OnDestroy, OnInit
{
  static nextId = 0;

  @HostBinding()
  id = `x-data-select-${DataSelectComponent.nextId++}`;
  controlType = 'x-data-select';

  @Output('selectionChange')
  selectionChangeEmitter = new EventEmitter();

  @Output('dataChange')
  dataChangeEmitter = new EventEmitter<DataChangeEvent<T>>();

  @ContentChild(DataSelectOptionDefDirective)
  optionTemplate?: DataSelectOptionDefDirective;

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    this.writeValue(value);
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  set emptyOption(hasEmptyOption: any) {
    this._emptyOptionEnabled = coerceBooleanProperty(hasEmptyOption);
  }

  @Input()
  set emptyLabel(label: string | number | null) {
    this._emptyLabel = label !== null ? String(label) : null;
  }

  _emptyOptionEnabled = false;
  _emptyLabel: string | null = 'None';
  _emptyOptionValue = Symbol('EmptyOption');

  get empty() {
    return Array.isArray(this._value) ? this._value.length === 0 : !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  get errorState(): boolean {
    return false;
  }

  @Input('aria-describedby')
  userAriaDescribedBy: string;

  @Input()
  set provider(provider: Type<IDataCollectionProvider<T, F, A> & IDataProvider<T>>) {
    let view = this.dataViewFactory.resolveSelectionView(provider);
    this._dataView = view;
  }
  @Input()
  set view(view: DataSelectView<T, F, A>) {
    this._dataView = view;
  }

  @Input()
  set filter(filter: F) {
    if (this._dataView) this._dataView.setFilter(filter);
    this._filter = filter;
  }

  @Input()
  set sort(sort: IDataSortModel) {
    if (this._dataView) this._dataView.setSort(sort);
    this._sort = sort;
  }

  @Input()
  set page(page: IDataPageModel) {
    if (this._dataView) this._dataView.setPage(page);
    this._page = page;
  }

  @Input()
  set multiple(multiple: any) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  get multiple() {
    return this._multiple;
  }

  _multiple = false;

  @ViewChild(MatSelect)
  matSelect: MatSelect;

  get focused() {
    return this.matSelect?.focused ?? false;
  }

  touched = false;
  stateChanges = new Subject<void>();

  _dataView: DataSelectView<T, F, A>;
  _filter: F | null = null;
  _sort: IDataSortModel | null = null;
  _page: IDataPageModel | null = null;

  resolveState$?: Observable<Operation<any>>;
  fetchState$: Observable<Operation<any>>;

  trackById = (i: number, op: DataSelectOptionObject<T>) => op.id;
  compareWith = (o1?: DataSelectOptionObject<T>, o2?: DataSelectOptionObject<T>): boolean => {
    const comparison =
      (o1 ? this._dataView.getId(o1) : o1) === (o2 ? this._dataView.getId(o2) : o2);
    return comparison;
  };
  displayWith = (o: DataSelectOptionObject<T>) => o.display;

  _placeholder: string;

  private _destroy$ = new Subject<void>();
  private _disabled = false;
  private _required = false;
  private _value: any;
  private _onChange: any = () => {};
  private _onTouch = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly changeRef: ChangeDetectorRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this._dataView) {
      throw new Error('DataView unset on DataSelectComponent');
    }
    if (this._filter) this._dataView.setFilter(this._filter);
    if (this._page) this._dataView.setPage(this._page);
    if (this._sort) this._dataView.setSort(this._sort);

    this._dataView.connect();

    this.fetchState$ = this._dataView.fetchState();
    this.resolveState$ = this._dataView.resolveState();

    this._dataView
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.changeRef.markForCheck();
      });

    this._dataView
      .selectionChanges()
      .pipe(
        // skip first write value event
        skip(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.dataChangeEmitter.emit(new DataChangeEvent(this._dataView.selectedOptions));
      });
  }

  ngOnDestroy() {
    if (this._dataView) {
      this._dataView.disconnect();
    }

    this.stateChanges.next();
    this.stateChanges.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Model -> View change
   */
  writeValue(obj: any): void {
    this._value = obj;

    if (Array.isArray(obj) || typeof obj === 'string' || typeof obj === 'number') {
      this._dataView.selectManyById(Array.isArray(obj) ? obj : [obj]);
    } else {
      this._dataView.clearSelection();
    }
    this.stateChanges.next();
  }

  onOptionSelected(
    values:
      | DataSelectOptionObject[]
      | DataSelectOptionObject
      | null
      | undefined
      | Symbol
      | Symbol[],
  ) {
    const isEmptyValue =
      (typeof values === 'symbol' && values === this._emptyOptionValue) ||
      (Array.isArray(values) && values.some((v) => v === this._emptyOptionValue));

    if (values === null || values === undefined || isEmptyValue) {
      this._value = this._multiple ? [] : null;
      this._dataView.clearSelection();
    } else if (Array.isArray(values)) {
      const filteredValues = (values as Array<any>).filter(
        (v: any): v is DataSelectOptionObject => v instanceof DataSelectOptionObject,
      ) as Array<DataSelectOptionObject>;

      this._dataView.selectMany(filteredValues, true);
      this._value = filteredValues.length === 0 ? null : filteredValues.map((o) => o.id);
    } else if (values instanceof DataSelectOptionObject) {
      this._dataView.select(values, true);
      this._value = values.id;
    }

    this._onChange(this._value);
    this._onTouch();
    this.selectionChangeEmitter.emit(new SelectionChangeEvent(this._value));

    this._dataView.resetResolveState();
    this.stateChanges.next();

    if (isEmptyValue) {
      this.matSelect.close();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    // const controlElement = this._elementRef.nativeElement
    //   .querySelector('.example-tel-input-container')!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if (this._disabled) return;
    this.matSelect.focus();
  }
}
