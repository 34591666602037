import { HttpErrorResponse } from '@angular/common/http';
import { RetryLink } from '@apollo/client/link/retry';
import { OperationDefinitionNode } from 'graphql';

export interface RetryLinkOptions {
  enabled: boolean;
  queries?: {
    retryOnStatus?: number[];
    maxRetries?: number;
  };
  mutations?: {
    retryOnStatus?: number[];
    maxRetries?: number;
  };
}

export function RetryLinkFactory(options: RetryLinkOptions) {
  return new RetryLink({
    attempts: (count, operation, error) => {
      const httpStatus = error instanceof HttpErrorResponse ? error.status : null;
      const operationType = operation.query.definitions.find(
        (d): d is OperationDefinitionNode => d.kind === 'OperationDefinition',
      )?.operation;

      let maxRetries: number = 0;
      let retryOnStatus: number[] = [];

      if (operationType === 'mutation' && options.mutations) {
        maxRetries = options.mutations.maxRetries ?? 3;
        retryOnStatus = options.mutations.retryOnStatus ?? [0];
      } else if (operationType === 'query' && options.queries) {
        maxRetries = options.queries.maxRetries ?? 3;
        retryOnStatus = options.queries.retryOnStatus ?? [0];
      }

      if (httpStatus !== null && retryOnStatus.includes(httpStatus) && count < maxRetries) {
        console.warn(
          `GraphModule: Retry Link caught error and will retry ${operationType} operation '${operation.operationName}'. Retry attempt ${count}/${maxRetries}`,
          {
            operationType,
            operationName: operation.operationName,
            count,
            maxRetries,
          },
        );
        return true;
      }

      return false;
    },
    delay: (count) => Math.min(Math.max(count * 2000, 2000), 10000),
  });
}
