import { Component, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'x-input-length-counter',
  template: `
    @if (control()) {
      <span>
        <b>
          {{ control()?.value?.length ?? 0 }}
          @if (max()) {
            <span>
              /
              {{ max() }}
            </span>
          }
        </b>
        characters used
      </span>
    }
  `,
})
export class InputLengthCounterComponent {
  control = input.required<AbstractControl | null | undefined>();
  max = input<number | undefined>();
}
