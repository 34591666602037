import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export type FileUploadDialogData = {
  title: string;
  message?: string;
  cancelLabel: string;
  submitLabel: string;
  multiple: boolean;
  required?: boolean;
  accept?: string[];
  files?: File[];
};

export type FileUploadDialogOptions = Partial<FileUploadDialogData>;

export type FileUploadDialogResult = {
  files: File[];
};

@Component({
  selector: 'x-file-upload-dialog',
  templateUrl: 'file-upload-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit {
  valid = !this.data.required;
  files$: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

  constructor(
    private dialog: MatDialogRef<FileUploadDialogComponent, FileUploadDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: FileUploadDialogData,
  ) {}

  ngOnInit() {
    this.files$.subscribe((files) => {
      this.valid = this.validateFiles(files);
    });

    if (this.data.files) {
      this.files$.next(this.data.files);
    }
  }

  fileInputChanged(event: Event) {
    let input = <HTMLInputElement>event.target;
    const fileList = input.files;

    if (fileList && fileList.length > 0) {
      const files = Array(fileList.length)
        .fill(0)
        .map((_, i) => fileList.item(i)) as File[];

      this.files$.next(files);
    }

    input.value = '';
  }

  removeFile(i: number) {
    const files = this.files$.value;
    files.splice(i, 1);
    this.files$.next(files);
  }

  validateFiles(files: File[]) {
    if (this.data.required) {
      return files.length > 0;
    }

    return files.map((file) => !this.validateFile(file)).some((valid) => !valid);
  }

  validateFile(file: File) {
    if (this.data.accept && this.data.accept.length > 0) {
      return this.data.accept.some((accept) => file.type === accept);
    }

    return false;
  }

  submit() {
    if (!this.validateFiles(this.files$.value)) {
      return;
    }

    this.dialog.close({
      files: this.files$.value,
    });
  }

  cancel() {
    this.dialog.close();
  }
}
