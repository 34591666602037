import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-password-input',
  templateUrl: './password-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _onChange: any = () => {};
  private _onTouched: any = () => {};

  @Input() label: string = 'Password';

  @Input() set validators(validators: ValidatorFn[]) {
    if (!validators) return;

    this.password.setValidators(validators);
    this.password.updateValueAndValidity();
  }

  hide: boolean = false;

  password = new UntypedFormControl(null);

  constructor(@Optional() private ngControl: NgControl) {
    if (!this.ngControl) return;

    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.password.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((val) => {
          this._onChange(val);
          this._onTouched();
        }),
      )
      .subscribe();
  }

  writeValue(password: string): void {
    if (!password) return;
    this.password.patchValue(password);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
