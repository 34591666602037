<h1 mat-dialog-title>{{ data.title }}</h1>
<form
  mat-dialog-content
  (ngSubmit)="
    dialog.close({
      assign: true,
      value: control.value
    })
  "
>
  <textarea
    *ngIf="long"
    matInput
    [formControl]="control"
    [placeholder]="data.placeholder ?? ''"
    class="mat-body-2"
    cdkTextareaAutosize
    cdkAutosizeMinRows="2"
    cdkAutosizeMaxRows="10"
  ></textarea>
  <input *ngIf="!long" matInput [formControl]="control" [placeholder]="data.placeholder ?? ''" />
</form>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{ assign: false, value: control.value }">
    {{ data.cancelLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="{ assign: true, value: control.value }"
  >
    {{ data.okLabel }}
  </button>
</div>
