<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <x-currency-input-control
    [formControl]="control"
    [currency]="data.currency"
    [placeholder]="data.placeholder ?? 'Enter amount'"
  ></x-currency-input-control>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{ assign: false, value: control.value }">
    {{ data.cancelLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="control.invalid"
    [mat-dialog-close]="{ assign: true, value: control.value }"
  >
    {{ data.okLabel }}
  </button>
</div>
