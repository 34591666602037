import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableOperation, OperationObserverService } from '@x/common/operation';
import { Observable, combineLatest, concat, forkJoin } from 'rxjs';

export type FileUploadEvent<T> = Observable<HttpEvent<T>>;
export type FileUploadsEvent<T> = Observable<HttpEvent<T>>[];
export type FileUploadEvents<T> = Observable<HttpEvent<T>[]>;

export type FileUploadOperation<T> = ObservableOperation<HttpEvent<T>>;
export type FileUploadsOperation<T> = ObservableOperation<HttpEvent<T>[]>;

export interface IFileUploadOptions {
  httpMethod?: 'POST' | 'PUT';
  reportProgress?: boolean;
  additionalData?: Record<string, string | Blob>;
  headers?: Record<string, string | string[]>;
  chunkSize?: number;
}

@Injectable()
export class FileUploadSevice {
  constructor(
    private readonly _http: HttpClient,
    private readonly operationObserver: OperationObserverService,
  ) {}

  createUpload<T>(
    uploadUri: string,
    file: File | Blob,
    options?: IFileUploadOptions,
  ): FileUploadEvent<T> {
    let data = new FormData();
    data.set('file', file);

    let headers = new HttpHeaders();

    if (options?.additionalData) {
      Object.entries(options.additionalData).forEach(([key, value]) => {
        data.append(key, value);
      });
    }

    if (options?.headers) {
      Object.entries(options.headers).forEach(([key, value]) => {
        headers = headers.set(key, value);
      });
    }

    const req = new HttpRequest(options?.httpMethod ?? 'POST', uploadUri, data, {
      reportProgress: options?.reportProgress ?? true,
      headers,
    });

    return this._http.request<T>(req);
  }

  createUploads<T>(
    uploadUri: string,
    files: File[],
    options?: IFileUploadOptions,
  ): FileUploadsEvent<T> {
    // TODO: possibly refactor this function to rather take `chunk` as a predicate fn argument to remove hard-coded cloudinary specifics
    return files.flatMap((file) => {
      if (!options?.chunkSize || file.size <= options.chunkSize) {
        return this.createUpload(uploadUri, file, options);
      }
      const { chunkSize } = options;
      const totalChunks = Math.ceil(file.size / chunkSize);

      const uploadId = `uqid-${Date.now()}`;
      const chunks: FileUploadsEvent<T> = [];

      let currentChunk = 0;
      const chunk = (start: number, end: number): void => {
        const contentRange = `bytes ${start}-${end - 1}/${file.size}`;
        const slice = file.slice(start, end, file.type);

        chunks.push(
          this.createUpload(uploadUri, slice, {
            ...options,

            // https://cloudinary.com/documentation/client_side_uploading#code_explorer_chunked_asset_upload_from_the_client_side
            headers: {
              'X-Unique-Upload-Id': uploadId,
              'Content-Range': contentRange,
            },
          }),
        );

        currentChunk++;

        if (currentChunk < totalChunks) {
          const nextStart = currentChunk * chunkSize;
          const nextEnd = Math.min(nextStart + chunkSize, file.size);
          return chunk(nextStart, nextEnd);
        }
      };

      const start = 0;
      const end = Math.min(chunkSize, file.size);
      chunk(start, end);

      return chunks;
    });
  }

  observeUpload<T>(upload: FileUploadEvent<T>): FileUploadOperation<T>;
  observeUpload<T>(
    uploadUri: string,
    file: File,
    options?: IFileUploadOptions,
  ): FileUploadOperation<T>;
  observeUpload<T>(
    uploadOrUri: FileUploadEvent<T> | string,
    file?: File,
    options?: IFileUploadOptions,
  ): FileUploadOperation<T> {
    let upload: FileUploadEvent<T>;

    if (typeof uploadOrUri === 'string') {
      if (!file) {
        throw new Error('file is required');
      }
      upload = this.createUpload(uploadOrUri, file, options);
    } else {
      upload = uploadOrUri;
    }

    return this.operationObserver.observe(upload);
  }

  observeUploads<T>(uploads: FileUploadsEvent<T>): FileUploadsOperation<T>;
  observeUploads<T>(
    uploadUri: string,
    files: File[],
    options?: IFileUploadOptions,
  ): FileUploadsOperation<T>;
  observeUploads<T>(
    uploadsOrUri: FileUploadsEvent<T> | string,
    files?: File[],
    options?: IFileUploadOptions,
  ): FileUploadsOperation<T> {
    let uploads: FileUploadsEvent<T>;

    if (typeof uploadsOrUri === 'string') {
      if (!files) {
        throw new Error('files are required');
      }
      uploads = this.createUploads(uploadsOrUri, files, options);
    } else {
      uploads = uploadsOrUri;
    }

    return this.operationObserver.observe(forkJoin(uploads));
  }

  batchUploads<T>(uploads: FileUploadsEvent<T>, batchSize: number): Observable<FileUploadEvents<T>>;
  batchUploads<T>(
    uploadUri: string,
    batchSize: number,
    files: File[],
    options?: IFileUploadOptions,
  ): Observable<FileUploadEvents<T>>;
  batchUploads<T>(
    uploadsOrUri: FileUploadsEvent<T> | string,
    batchSize: number,
    files?: File[],
    options?: IFileUploadOptions,
  ): Observable<FileUploadEvents<T>> {
    if (batchSize <= 0) {
      throw new Error('Error calling batchUploads: expected positive batchSize');
    }
    let uploads: FileUploadsEvent<T>;

    if (typeof uploadsOrUri === 'string') {
      if (!files) {
        throw new Error('Error calling batchUploads: files are required');
      }

      uploads = this.createUploads(uploadsOrUri, files, options);
    } else {
      uploads = uploadsOrUri;
    }

    const queue: Array<FileUploadEvents<T>> = [];
    for (let i = 0; i < uploads.length; i += batchSize) {
      const batch = uploads.slice(i, i + batchSize);

      const p: FileUploadEvents<T> = combineLatest(batch);
      queue.push(p);
    }

    return concat(queue);
  }
}
