<input matInput type="text" [value]="formattedValue" [placeholder]="placeholder" readonly />
<input
  type="hidden"
  [matDatepicker]="picker"
  [formControl]="dateControl"
  [placeholder]="placeholder"
/>
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker>
  <mat-datepicker-actions>
    <mat-form-field appearance="outline">
      <mat-label>Time</mat-label>
      <x-time-input-control [formControl]="timeControl"></x-time-input-control>
    </mat-form-field>
    <div class="buttons">
      <button matDatepickerCancel mat-flat-button color="accent">Cancel</button>
      <button matDatepickerApply mat-flat-button color="primary">Apply</button>
    </div>
  </mat-datepicker-actions>
</mat-datepicker>
