import { Component, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'x-form-validity-pill',
  template: `
    <x-pill [color]="_color" [matTooltip]="tooltip">
      <span>{{ _label }}</span>
      <fa-icon [icon]="_icon"></fa-icon>
    </x-pill>
  `,
})
export class FormValidityPillComponent {
  form = input.required<FormGroup>();
  label = input<string>();

  get _color(): string {
    const form = this.form();
    if (form.invalid) return 'warn';
    return 'light-green';
  }

  get _label(): string {
    const label = this.label();
    if (label) return label;

    const form = this.form();
    if (form.valid) return 'Form is valid';
    if (form.invalid) return 'Form is invalid';
    return '';
  }

  get tooltip() {
    if (this.label()) {
      const form = this.form();
      if (form.valid) return 'Form is valid';
      if (form.invalid) return 'Form is invalid';
    }
    return '';
  }

  get _icon(): IconProp {
    const form = this.form();
    if (form.disabled || form.valid) return 'check';
    return 'times';
  }
}
