import { Pipe, PipeTransform } from '@angular/core';

export type DateTimeRelativity = 'past' | 'future' | 'present';

export function getDateRelativity(value: Date, relativeTo: Date = new Date()): DateTimeRelativity {
  if (relativeTo < value) {
    return 'future';
  } else if (relativeTo > value) {
    return 'past';
  }
  return 'present';
}

@Pipe({
  name: 'relativity',
})
export class DateTimeRelativityPipe implements PipeTransform {
  transform(value: any, ...args: any[]): DateTimeRelativity | null {
    if (value == null || value === '' || value !== value) return null;
    return getDateRelativity(new Date(value));
  }
}
