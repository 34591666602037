import { ChangeDetectionStrategy, Component, Inject, Type } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDataCollectionProvider, IDataProvider } from '@x/common/data';

export interface IDataSelectDialogData<R = string | number | null | string[] | number[]> {
  title: string;
  message?: string;
  okLabel: string;
  cancelLabel: string;
  provider: Type<IDataCollectionProvider & IDataProvider>;
  value?: R;
  filter?: any;
  multiple?: boolean;
  placeholder: string;
}

export interface IDataSelectDialogOptions<R = string | number | null | string[] | number[]> {
  title?: string;
  message?: string;
  okLabel?: string;
  cancelLabel?: string;
  provider: Type<IDataCollectionProvider & IDataProvider>;
  value?: R;
  filter?: any;
  multiple?: boolean;
  placeholder?: string;
}

export interface IDataSelectDialogResult<R = string | number | null | string[] | number[]> {
  assign: boolean;
  value: R;
}

@Component({
  selector: 'x-data-select-dialog',
  templateUrl: 'data-select-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSelectDialogComponent {
  control = new UntypedFormControl(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IDataSelectDialogData<any>,
  ) {}
}
