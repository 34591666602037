import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'x-form-panel',
  templateUrl: 'form-panel.component.html',
  styleUrls: ['form-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-form-panel container-fluid',
  },
})
export class FormPanelComponent {
  @Input()
  set dense(dense: string | boolean) {
    this._dense = coerceBooleanProperty(dense);
  }

  @HostBinding('class.dense')
  _dense = false;
}
