import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { TSearchQuery } from '@x/common/form/components/address-autocomplete/address-autocomplete';
import { AddressLoaderDirective } from '@x/common/form/components/address-autocomplete/address-loader.directive';
import { GeocodeService, IAutocompleteAddressSuggestion } from '@x/geocode/client';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'x-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: AddressAutocompleteComponent }],
  host: {
    class: 'x-address-autocomplete',
  },
})
export class AddressAutocompleteComponent
  implements MatFormFieldControl<any>, OnInit, OnDestroy, DoCheck, ControlValueAccessor
{
  searching$ = new BehaviorSubject<boolean>(false);
  addressSearchControl = new FormControl<string>('', { nonNullable: true });

  @ContentChild(AddressLoaderDirective, { read: TemplateRef }) loaderTemplate: TemplateRef<any>;

  @Input() countryCode: string | undefined | null;

  @Input() overlayOriginElement?: ElementRef;

  @Output() searchQuery = new EventEmitter<TSearchQuery>();

  /*addressSearchInput*/
  _addressSearchInput: ElementRef;
  addressSearchInputFocused$ = new BehaviorSubject(false);
  @ViewChild('addressSearchInput') set addressSearchInput(input: ElementRef) {
    if (!input) return;

    if (!this.addressSearchInput) {
      setTimeout(() => {
        input.nativeElement.focus();
        this.openSuggestions$.next(true);
      }, 200);

      this._addressSearchInput = input;
    }
  }

  get addressSearchInput() {
    return this._addressSearchInput;
  }

  @ViewChild('tooltip') tooltip: MatTooltip;

  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  autofilled = false;
  controlType = 'address-autocomplete';
  id = `address-autocomplete-${AddressAutocompleteComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const { value } = this.addressSearchControl;

    return !value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = 'Start searching...';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.addressSearchControl.disable() : this.addressSearchControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string {
    if (this.addressSearchControl.valid) {
      return this.addressSearchControl.value;
    }
    return '';
  }
  set value(search: string) {
    this.addressSearchControl.patchValue(search, { emitEvent: false });
    this.stateChanges.next();
  }

  errorState = false;

  private suggestionContainer$ = new BehaviorSubject<IAutocompleteAddressSuggestion | null>(null);

  private addressSearchControlValueChanges$ = this.addressSearchControl.valueChanges.pipe(
    tap(() => {
      this.searching$.next(true);
      this.suggestionContainer$.next(null);
      this.disableTooltip$.next(true);
    }),
    startWith(''),
    debounceTime(300),
    distinctUntilChanged(),
  );

  openSuggestions$ = new BehaviorSubject(false);

  addresses$: Observable<IAutocompleteAddressSuggestion[]> = combineLatest([
    this.addressSearchControlValueChanges$,
    this.suggestionContainer$,
  ]).pipe(
    tap(() => this.searching$.next(true)),
    switchMap(([value, suggestion]) => this.autocompleteAddress(value, suggestion?.id)),
    tap((suggestions) => {
      this.searching$.next(false);
    }),
    shareReplay(),
  );

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    private geocodeService: GeocodeService,
    @Optional()
    @Self()
    public ngControl: NgControl,
    @Optional()
    private _parentForm?: NgForm,
    @Optional()
    private _parentFormGroup?: FormGroupDirective,
    @Optional()
    public parentFormField?: MatFormField,
    @Optional()
    @Inject(MAT_FORM_FIELD)
    public _formField?: MatFormField,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.address-autocomplete-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick = () => {
    this.addressSearchInput.nativeElement.focus();
  };

  writeValue(search: string | null): void {
    if (!search) return;

    this.value = search;
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.openSuggestions$.next(true);
      this.stateChanges.next();

      console.log('focusin');
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;

    if (!parent) return;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.ngControl) this.updateErrorState();
  }

  suggestionSelected($event: IAutocompleteAddressSuggestion | null) {
    if (!$event) {
      this.suggestionContainer$.next(null);
      this.addressSearchInput.nativeElement.focus();
      return;
    }

    const { type } = $event;

    if (type === 'container') {
      this.addressSearchInput.nativeElement.focus();
      this.suggestionContainer$.next($event);
      return;
    }

    this.expandAddress($event);
  }

  async expandAddress(suggestion: IAutocompleteAddressSuggestion) {
    try {
      this.openSuggestions$.next(false);
      this.searching$.next(true);

      const address = await firstValueFrom(
        this.geocodeService.expandAddress(
          suggestion.id,
          this.addressSearchControl.value,
          this.countryCode,
        ),
      );

      this.addressSearchControl.patchValue(address.full, { emitEvent: false });
      this.onChange(address);
      this.onTouched();
      this.searching$.next(false);
    } catch (e) {
      console.log('could not expand address');
      this.showTooltip();
      this.searching$.next(false);
    }
  }

  private autocompleteAddress(
    value: string,
    id?: string,
  ): Observable<IAutocompleteAddressSuggestion[]> {
    if (value.length === 0) return of([]);

    return this.geocodeService.autocompleteAddress(value, this.countryCode, id).pipe(
      map((result) => result.suggestions),
      tap((suggestions) => {
        this.searchQuery.emit({
          query: value,
          suggestions,
        });
      }),
      catchError(() => of([])),
    );
  }

  disableTooltip$ = new BehaviorSubject(true);
  private showTooltip() {
    this.disableTooltip$.next(false);

    setTimeout(() => {
      this.tooltip.show();
    }, 0);
  }

  outsideClick($event: MouseEvent) {
    if (this.addressSearchInputFocused$.value) return;
    this.openSuggestions$.next(false);
  }
}
