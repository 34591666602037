import { Inject, Injectable, Injector, Type } from '@angular/core';
import { OperationObserverService } from '@x/common/operation';
import { DATA_TABLE_VIEW_STORAGE, IDataTableViewStorage } from '../state/data-table-view-state';
import { IDataCollectionProvider, IDataProvider, IDataTreeProvider } from '../types/data-provider';
import { DataCollectionView, IDataCollectionViewOptions } from '../views/data-collection-view';
import { DataSelectView } from '../views/data-select-view';
import { DataTableView, IDataTableViewOptions } from '../views/data-table-view';
import { DataTreeView, IDataTreeViewOptions } from '../views/data-tree-view';
import { DataView, IDataViewOptions } from '../views/data-view';

@Injectable({ providedIn: 'root' })
export class DataViewFactoryService {
  constructor(
    private injector: Injector,
    private operationService: OperationObserverService,
    @Inject(DATA_TABLE_VIEW_STORAGE)
    private viewStorage: IDataTableViewStorage<any, any, any, any>,
  ) {}

  resolveView<T = any, I = any>(
    type: Type<IDataProvider<T, I>>,
    options?: IDataViewOptions<I>,
  ): DataView<T, I> {
    const provider = this.injector.get<IDataProvider<T, I>>(type, undefined);
    return this.makeView<T, I>(provider, options);
  }

  makeView<T = any, I = any>(
    provider: IDataProvider<T, I>,
    options?: IDataViewOptions<I>,
  ): DataView<T, I> {
    return new DataView<T, I>(provider, this.operationService, options);
  }

  resolveCollectionView<T = any, F = any, A = any>(
    type: Type<IDataCollectionProvider<T, F, A>>,
    options?: IDataCollectionViewOptions<T, F, A>,
  ): DataCollectionView<T, F, A> {
    const provider = this.injector.get<IDataCollectionProvider>(type, undefined);
    return this.makeCollectionView(provider, options);
  }

  makeCollectionView<T = any, F = any, A = any>(
    provider: IDataCollectionProvider<T, F, A>,
    options?: IDataCollectionViewOptions<T, F, A>,
  ): DataCollectionView<T, F, A> {
    return new DataCollectionView(provider, this.operationService, options);
  }

  resolveSelectionView<T = any, F = any, A = any>(
    type: Type<IDataCollectionProvider<T, F, A> & IDataProvider<T>>,
    options?: IDataCollectionViewOptions,
  ): DataSelectView<T, F, A> {
    const provider = this.injector.get<IDataCollectionProvider<T, F, A> & IDataProvider<T>>(
      type,
      undefined,
    );
    return this.makeSelectionView<T, F, A>(provider, options);
  }

  makeSelectionView<T = any, F = any, A = any>(
    provider: IDataCollectionProvider<T, F, A> & IDataProvider<T>,
    options?: IDataCollectionViewOptions,
  ): DataSelectView<T, F, A> {
    return new DataSelectView(provider, this.operationService, options);
  }

  resolveTableView<T = any, F = any, A = any, I = string | number>(
    type: Type<IDataCollectionProvider<T, F, A, I>>,
    options: IDataTableViewOptions<T, F, A>,
  ): DataTableView<T, F, A, I> {
    const provider = this.injector.get<IDataCollectionProvider<T, F, A, I> & IDataProvider<T, I>>(
      type,
      undefined,
    );
    return this.makeTableView<T, F, A, I>(provider, options);
  }

  makeTableView<T = any, F = any, A = any, I = string | number>(
    provider: IDataCollectionProvider<T, F, A, I>,
    options: IDataTableViewOptions<T, F, A>,
  ): DataTableView<T, F, A, I> {
    return new DataTableView<T, F, A, I>(
      provider,
      options,
      this.operationService,
      this.viewStorage,
    );
  }

  resolveTreeView<T = any, F = any, A = any, I = string | number>(
    type: Type<IDataTreeProvider<T, F, A, I>>,
    options: IDataTreeViewOptions<T, F, A>,
  ): DataTreeView<T, F> {
    const provider = this.injector.get<IDataTreeProvider<T, F, A, I>>(type, undefined);
    return this.makeTreeView<T, F, A, I>(provider, options);
  }

  makeTreeView<T = any, F = any, A = any, I = string | number>(
    provider: IDataTreeProvider<T, F, A, I>,
    options: IDataTreeViewOptions<T, F, A>,
  ): DataTreeView<T, F, A, I> {
    return new DataTreeView<T, F, A, I>(provider, options, this.operationService);
  }
}
