<mat-select
  (valueChange)="onOptionSelected($event)"
  [multiple]="_multiple"
  [value]="_multiple ? _dataView.selectedOptions : _dataView.selectedOptions[0]"
  [compareWith]="compareWith"
  [placeholder]="_placeholder"
  [disabled]="disabled"
  panelClass="x-data-select-panel"
  class="selection"
  [disableOptionCentering]="true"
>
  <mat-select-trigger>
    <ng-container
      *ngFor="let option of _dataView.selectedOptions; trackBy: trackById"
      class="selection"
    >
      <ng-container
        [ngTemplateOutlet]="optionTemplate?.template ?? defaultTriggerTemplate"
        [ngTemplateOutletContext]="{ $implicit: option }"
        class="selection"
      ></ng-container>
    </ng-container>
  </mat-select-trigger>
  <mat-option *ngIf="_emptyOptionEnabled" [value]="_emptyOptionValue">
    {{ _emptyLabel ?? 'None' }}
  </mat-option>
  <mat-option *ngFor="let option of _dataView.options; trackBy: trackById" [value]="option">
    <ng-container
      [ngTemplateOutlet]="optionTemplate?.template ?? defaultOptionTemplate"
      [ngTemplateOutletContext]="{ $implicit: option }"
    ></ng-container>
  </mat-option>
</mat-select>

<ng-template #defaultOptionTemplate let-option>
  <span class="default-option mat-body-2">{{ option.display }}</span>
</ng-template>

<ng-template #defaultTriggerTemplate let-option>
  <span class="default-trigger mat-body-2">{{ option.display }}</span>
</ng-template>

<ng-container *ngIf="fetchState$ | async as state" [xOperation]="state">
  <ng-container *xOperationError="let error">
    <fa-icon icon="circle-exclamation" [matTooltip]="error.title"></fa-icon>
  </ng-container>
  <ng-container *xOperationLoading>
    <fa-icon icon="spinner" matTooltip="Fetching list..."></fa-icon>
  </ng-container>
</ng-container>

<ng-container *ngIf="resolveState$ | async as state" [xOperation]="state">
  <ng-container *xOperationError="let error">
    <fa-icon icon="circle-exclamation" [matTooltip]="error.title"></fa-icon>
  </ng-container>
  <ng-container *xOperationLoading>
    <fa-icon icon="spinner" matTooltip="Resolving items..."></fa-icon>
  </ng-container>
</ng-container>
