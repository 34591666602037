import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@x/common/icon';
import { LocaleModule } from '@x/common/locale';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '../form';
import { DataAutocompleteDialogComponent } from './components/data-autocomplete-dialog/data-autocomplete-dialog.component';
import { DataSelectDialogComponent } from './components/data-select-dialog/data-select-dialog.component';
import { DialogLayoutComponent } from './components/dialog-layout/dialog-layout.component';
import { DialogPanelComponent } from './components/dialog-panel/dialog-panel.component';
import { DialogToolbarComponent } from './components/dialog-toolbar/dialog-toolbar.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { MoneyInputDialogComponent } from './components/money-input-dialog/money-input-dialog.component';
import { NumberInputDialogComponent } from './components/number-input-dialog/number-input-dialog.component';
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component';
import { TextInputDialogComponent } from './components/text-input-dialog/text-input-dialog.component';
import { DIALOG_LANGUAGE } from './dialog.language';
import { ErrorDialogService } from './services/error-dialog.service';
import { PromptDialogService } from './services/prompt-dialog.service';

const DECLARATIONS = [
  PromptDialogComponent,
  DialogLayoutComponent,
  DialogToolbarComponent,
  DialogPanelComponent,
  ErrorDialogComponent,
  DataSelectDialogComponent,
  DataAutocompleteDialogComponent,
  TextInputDialogComponent,
  NumberInputDialogComponent,
  MoneyInputDialogComponent,
  FileUploadDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    DashboardCoreModule,
    DashboardFormModule,
    LocaleModule.forChild(DIALOG_LANGUAGE),
  ],
  providers: [PromptDialogService, ErrorDialogService],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class DashboardDialogModule {}
