import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IMoneyInputDialogData {
  title: string;
  placeholder?: string;
  okLabel: string;
  cancelLabel: string;
  value?: number | null;
  required?: boolean;
  min?: number;
  max?: number;
  currency: string;
}

export interface IMoneyInputDialogOptions {
  title?: string;
  placeholder?: string;
  okLabel?: string;
  cancelLabel?: string;
  value?: number | null;
  required?: boolean;
  min?: number;
  max?: number;
  currency: string;
}

export interface IMoneyInputDialogResult {
  assign: boolean;
  value: number | null;
}

@Component({
  selector: 'x-money-input-dialog',
  templateUrl: 'money-input-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['money-input-dialog.component.scss'],
})
export class MoneyInputDialogComponent {
  control = new FormControl<number | null>(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IMoneyInputDialogData,
  ) {}
}
