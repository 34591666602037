import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, Subscription, merge, of } from 'rxjs';
import { DatetimeFormatter } from '../../datetime-formatter';

@Component({
  selector: 'x-datetime',
  template: `
    {{ displayValue }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: inline;
      }
    `,
  ],
})
export class DateTimeComponent implements OnInit, OnDestroy {
  @HostBinding('attr.title')
  titleValue = '';
  displayValue: string = '';

  @Input('datetime')
  set datetime(value: any) {
    this._datetime = value;
    this._changes$.next();
  }

  @Input('zone')
  set timezone(zone: string) {
    this._timezone = zone;
    this._changes$.next();
  }

  @Input('format')
  set format(format: string) {
    this._format = format;
    this._changes$.next();
  }

  private _datetime?: any;
  private _timezone?: string;
  private _format?: string;
  private _changes$ = new Subject<void>();
  private _sub: Subscription;

  constructor(
    private dateFormatter: DatetimeFormatter,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._sub = merge(this._changes$, this.dateFormatter.changes$, of(true)).subscribe(() => {
      this.titleValue = this.dateFormatter.toFormat(this._datetime, 'full', this._timezone);
      this.displayValue = this.dateFormatter.toFormat(this._datetime, this._format, this._timezone);
      this.changeRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
    this._changes$.complete();
  }
}
