import { SelectionModel } from '@angular/cdk/collections';
import { Operation, OperationObserverService, OperationSuccessState } from '@x/common/operation';
import { makeId } from '@x/common/utils';
import { BehaviorSubject, Observable, concat, forkJoin, merge, of } from 'rxjs';
import { debounceTime, finalize, skip, switchMap, tap } from 'rxjs/operators';
import { IDataTableViewState, IDataTableViewStorage } from '../state/data-table-view-state';
import { IDataPageModel } from '../types/data-model';
import { IDataCollectionProvider } from '../types/data-provider';
import { DataCollectionView, IDataCollectionViewOptions } from './data-collection-view';

export interface IColumnDefinition<T = any> {
  id: string;
  title: string;
  sortable?: boolean;
  sortId?: string;
  droppable?: boolean;
}

export interface ISortDefinition {
  id: string;
  title: string;
}

export interface IDataTableViewOptions<T = any, F = any, A = any>
  extends IDataCollectionViewOptions<T, F, A> {
  id: string;
  columns: IColumnDefinition<T>[];
  sorts?: ISortDefinition[];
  defaultColumns: string[];
  pageSizes: number[];
  enableStorage?: boolean;
  draggableRows?: boolean;
  reorderableRows?: boolean;
  rowPositionField?: keyof T;
  rowPositionFn?: (row: T) => number | undefined;
}

export type SelectionState = 'all' | 'none' | 'some';

export class DataTableView<
  T = any,
  F = any,
  A = any,
  I = string | number,
> extends DataCollectionView<T, F, A, I> {
  get id() {
    return this._id;
  }

  get stateId() {
    return this._stateId;
  }

  get stateTitle() {
    return this._stateTitle;
  }

  get pageSizes() {
    return this._pageSizes;
  }
  set pageSizes(sizes: number[]) {
    this.setPageSizes(sizes);
  }

  get selectedColumns() {
    return this._selectedColumns;
  }
  set selectedColumns(columns: string[]) {
    this.setSelectedColumns(columns);
  }

  get sorts() {
    return this._sorts;
  }

  get columns() {
    return this._columns;
  }
  set columns(columns: IColumnDefinition<T>[]) {
    this.setColumns(columns);
  }

  get selected() {
    return this._selectionModel.selected;
  }

  get selectionCount() {
    return this._selectionModel.selected.length ?? 0;
  }

  get itemLoadingCount() {
    return this._loadingModel.selected.length ?? 0;
  }

  get active() {
    return this.getActive();
  }

  get activeItem() {
    return this.getActiveItem();
  }

  selectionState: SelectionState = 'none';
  itemLoadingState: SelectionState = 'none';

  private readonly _id: string;
  private _stateId: string = makeId(32);
  private _stateTitle?: string | null = 'Untitled View';
  private _stateDescription?: string | null;

  private _sorts: ISortDefinition[] = [];
  private _columns: IColumnDefinition<T>[] = [];
  private _selectedColumns: string[] = [];
  private _defaultColumns: string[] = [];
  private _pageSizes: number[] = [10, 20, 50];

  protected _activeId$ = new BehaviorSubject<I | null>(null);
  protected _selectionModel = new SelectionModel<I>(true);
  protected _loadingModel = new SelectionModel<I>(true);
  protected _loading = false;

  protected _enableStorage: boolean;

  constructor(
    provider: IDataCollectionProvider<T, F, A, I>,
    public options: IDataTableViewOptions<T, F, A>,
    operations: OperationObserverService,
    private viewStorage: IDataTableViewStorage<T, F, A, I>,
  ) {
    super(provider, operations, options);

    this._id = options.id ?? 'default';
    this._columns = options.columns;
    this._defaultColumns = this._selectedColumns = options.defaultColumns;
    this._pageSizes = options.pageSizes;
    this._enableStorage = options.enableStorage ?? true;

    this._sorts = (options.sorts ?? []).concat(
      options.columns
        .filter((c) => c.sortable)
        .map((c) => ({ id: c.sortId ?? c.id, title: c.title })),
    );

    if (this._enableStorage) {
      const state = viewStorage.restoreCurrentState(this._id);
      if (state) {
        console.log('restored state', state);
        this.setState(state);
      }
    }

    this.bindObservable(this.observeSelectionChanges());
    this.bindObservable(this.observeStateChanges());
    this.bindObservable(this.observeDataChanges());
  }

  setColumns(columns: IColumnDefinition<T>[]) {
    this._columns = columns;
    this._displayChange$.next();
  }

  setPageSizes(sizes: number[]) {
    this._pageSizes = sizes;
    this._displayChange$.next();
  }

  setSelectedColumns(columns: string[]) {
    this._selectedColumns = columns;
    this._displayChange$.next();
  }

  resetVisibleColumns() {
    this.selectedColumns = this._defaultColumns;
    this._displayChange$.next();
  }

  isSelected(item: T): boolean;
  isSelected(id: I): boolean;
  isSelected(itemOrId: T | I): boolean;
  isSelected(itemOrId: T | I): boolean {
    return this._selectionModel.isSelected(this.coerceItemId(itemOrId));
  }

  isSelectionEmpty() {
    return this._selectionModel.isEmpty();
  }

  select(item: T): void;
  select(id: I): void;
  select(ids: Array<I>): void;
  select(itemOrId: T | I): void;
  select(itemOrId: T | I | Array<I>): void {
    Array.isArray(itemOrId)
      ? itemOrId.forEach((id) => this._selectionModel.select(this.coerceItemId(id)))
      : this._selectionModel.select(this.coerceItemId(itemOrId));
    this._displayChange$.next();
  }

  deselect(item: T): void;
  deselect(id: I): void;
  deselect(ids: Array<I>): void;
  deselect(itemOrId: T | I): void;
  deselect(itemOrId: T | I | Array<I>): void {
    Array.isArray(itemOrId)
      ? itemOrId.forEach((id) => this._selectionModel.deselect(this.coerceItemId(id)))
      : this._selectionModel.deselect(this.coerceItemId(itemOrId));

    this._displayChange$.next();
  }

  toggleSelect(item: T): void;
  toggleSelect(id: I): void;
  toggleSelect(itemOrId: T | I): void {
    if (this.isSelected(itemOrId)) {
      this.deselect(itemOrId);
    } else {
      this.select(itemOrId);
    }
  }

  selectAll() {
    let ids: Array<I> = this.items.map((r) => this.getId(r));
    this._selectionModel.select(...ids);
    this._displayChange$.next();
  }

  clearSelection() {
    this._selectionModel.clear();
    this._displayChange$.next();
  }

  getActive() {
    return this._activeId$.getValue();
  }

  getActiveItem() {
    const activeId = this._activeId$.getValue();
    return activeId ? this.getItem(activeId) : null;
  }

  setActive(id: I) {
    this._activeId$.next(id);
    this._displayChange$.next();
  }

  hasActive() {
    return this._activeId$.getValue() !== null;
  }

  clearActive() {
    this._activeId$.next(null);
    this._displayChange$.next();
  }

  activeChanges() {
    return this._activeId$.pipe(skip(1));
  }

  activeItemSubject() {
    return this._activeId$.pipe(
      switchMap((e) => {
        const active = this.getActive();
        if (active) {
          return this.item(active);
        } else {
          return of(null);
        }
      }),
    );
  }

  activeId(): Observable<I | null> {
    return this._activeId$;
  }

  setLoading(isLoading = true) {
    this._loading = isLoading;
    this._displayChange$.next();
  }

  startLoading() {
    this.setLoading(true);
  }

  stopLoading() {
    this.setLoading(false);
  }

  isAnyItemLoading() {
    return this._loadingModel.selected.length > 0;
  }

  isItemLoading(itemOrId: I | T) {
    return this._loadingModel.isSelected(this.coerceItemId(itemOrId));
  }

  setItemLoading(itemOrId: I | T | Array<I | T>, loading = true) {
    const items = (Array.isArray(itemOrId) ? itemOrId : [itemOrId]).map((itemOrId) =>
      this.coerceItemId(itemOrId),
    );

    loading ? this._loadingModel.select(...items) : this._loadingModel.deselect(...items);
    this._displayChange$.next();
  }

  startItemLoading(itemOrId: I | T | Array<I | T>) {
    this.setItemLoading(itemOrId);
  }

  stopItemLoading(itemOrId: I | T | Array<I | T>) {
    this.setItemLoading(itemOrId, false);
  }

  reset() {
    this._selectionModel.clear();
    this._activeId$.next(null);
    this._stateId = makeId(32);
    this._stateTitle = 'Untitled View';
    this._stateDescription = null;

    super.reset();
  }

  mutate<R = any>(
    mutation: () => Observable<R>,
    { refresh, label }: { refresh?: boolean; label?: string } = {
      refresh: true,
      label: 'Mutation',
    },
  ) {
    return of(null).pipe(
      tap((id) => this.setLoading(true)),
      switchMap((id) => this.operationService.observe(mutation())),
      finalize(() => {
        this.setLoading(false);
        if (refresh ?? true) this.refresh();
      }),
    );
  }

  mutateRow<R = any>(
    id: I,
    mutation: (id: I) => Observable<R>,
    { refresh, deselect, label }: { refresh?: boolean; deselect?: boolean; label?: string } = {
      refresh: true,
      deselect: true,
      label: 'Mutation',
    },
  ) {
    return of(id).pipe(
      tap((id) => this.setItemLoading(id)),
      switchMap((id) => this.operationService.observe(mutation(id))),
      tap((s) => {
        if ((deselect ?? true) && s instanceof OperationSuccessState) {
          this.deselect(id);
        }
      }),
      finalize(() => {
        this.stopItemLoading(id);
        if (refresh ?? true) this.refresh();
      }),
    );
  }

  mutateRows<R = any>(
    ids: I[],
    mutation: (ids: I[]) => Observable<R>,
    { refresh, deselect, label }: { refresh?: boolean; deselect?: boolean; label?: string } = {
      refresh: true,
      deselect: true,
      label: 'Mutation',
    },
  ) {
    return of(ids).pipe(
      tap((ids) => this.setItemLoading(ids)),
      switchMap((ids) => this.operationService.observe(mutation(ids))),
      tap((s) => {
        if ((deselect ?? true) && s instanceof OperationSuccessState) {
          this.deselect(ids);
        }
      }),
      finalize(() => {
        this.stopItemLoading(ids);
        if (refresh ?? true) this.refresh();
      }),
    );
  }

  mutateEachRow<R = any>(
    ids: Array<I>,
    mutation: (id: I) => Observable<R>,
    { refresh, deselect, label }: { refresh?: boolean; deselect?: boolean; label?: string } = {
      refresh: true,
      deselect: true,
    },
  ) {
    return forkJoin(
      ids.map((id) =>
        this.mutateRow(id, mutation, {
          refresh: false,
          deselect: deselect ?? true,
          label,
        }),
      ),
    ).pipe(
      finalize(() => {
        if (refresh ?? true) this.refresh();
      }),
    );
  }

  mutateEachSelected<R = any>(
    mutation: (id: I) => Observable<R>,
    options: { refresh?: boolean; deselect?: boolean; label?: string } = {
      refresh: true,
      deselect: true,
    },
  ) {
    return this.mutateEachRow<R>(this.selected, mutation, options);
  }

  mutateSelected<R = any>(
    mutation: (id: I[]) => Observable<R>,
    options: { refresh?: boolean; deselect?: boolean; label?: string } = {
      refresh: true,
      deselect: true,
    },
  ) {
    return this.mutateRows(this.selected, mutation, options);
  }

  mutateSelectedBatched<R = any>(
    mutation: (id: I[]) => Observable<R>,
    options: { refresh?: boolean; deselect?: boolean; label?: string; batchSize?: number } = {
      batchSize: 1,
      refresh: true,
      deselect: true,
    },
  ) {
    let batchSize = options.batchSize ?? 1;
    if (batchSize <= 0)
      throw new Error('error calling mutateSelectedBatch. expected positive batchSize');

    const queue: Array<Observable<Operation>> = [];
    for (let i = 0; i < this.selected.length; i += batchSize) {
      const batch = this.selected.slice(i, i + batchSize);
      queue.push(this.mutateRows(batch, mutation, options));
    }
    return concat(...queue);
  }

  mutateEachSelectedBatched<R = any>(
    mutation: (id: I) => Observable<R>,
    options: { refresh?: boolean; deselect?: boolean; label?: string; batchSize?: number } = {
      batchSize: 1,
      refresh: true,
      deselect: true,
    },
  ) {
    let batchSize = options.batchSize ?? 1;
    if (batchSize <= 0)
      throw new Error('error calling mutateSelectedBatch. expected positive batchSize');

    const queue: Array<Observable<Operation[]>> = [];
    for (let i = 0; i < this.selected.length; i += batchSize) {
      const batch = this.selected.slice(i, i + batchSize);
      queue.push(this.mutateEachRow(batch, mutation, options));
    }
    return concat(...queue);
  }

  setViewTitle(title: string) {
    this._stateTitle = title;
    this._displayChange$.next();
  }

  setState(
    save: IDataTableViewState<T, F, A, I>,
    options?: {
      restorePageIndex?: boolean;
      restoreSelection?: boolean;
      restoreActive?: boolean;
      restoreDisplayOptions?: boolean;
    },
  ) {
    const restorePageIndex = options?.restorePageIndex ?? true;
    const restoreSelection = options?.restoreSelection ?? true;
    const restoreActive = options?.restoreActive ?? true;
    const restoreDisplayOptions = options?.restoreDisplayOptions ?? true;

    const page: IDataPageModel = {
      index: restorePageIndex ? save.page?.index ?? 0 : 0,
      size: save.page?.size ?? this._defaultPage.size,
    };
    this._page = page;

    this._stateId = save.id;
    this._stateTitle = save.title ?? 'Untitled View';
    this._stateDescription = save.description;
    this._searchText = save.searchText ?? null;
    this._filter = save.filter ?? this._defaultFilter;
    this._args = save.args ?? this._defaultArgs;
    this._sort = save.sort ?? this._defaultSort;
    this._selectedColumns = save.visibleColumns ?? this._defaultColumns;

    if (restoreSelection && save.selected) {
      this._selectionModel.select(...save.selected);
    } else {
      this._selectionModel.clear();
    }

    if (restoreActive && save.active) {
      this._activeId$.next(save.active);
    }

    if (restoreDisplayOptions && save.displayOptions) {
      this._displayOptions = save.displayOptions;
    } else {
      this._displayOptions = this._defaultDisplayOptions;
    }

    if (save.displayOptions) this._displayChange$.next();
    this._queryChange$.next(true);
  }

  getState(): IDataTableViewState<T, F, A, I> {
    return JSON.parse(
      JSON.stringify({
        id: this._stateId,
        title: this._stateTitle,
        description: this._stateDescription,
        sort: this._sort,
        page: this._page,
        filter: this._filter,
        searchText: this._searchText,
        args: this._args,
        visibleColumns: this._selectedColumns,
        selected: this._selectionModel.selected,
        active: this._activeId$.getValue() ?? null,
        displayOptions: this._displayOptions ?? null,
      } as IDataTableViewState<T, F, A, I>),
    );
  }

  cloneState(): IDataTableViewState<T, F, A, I> {
    return {
      ...this.getState(),
      id: makeId(32),
    };
  }

  private observeStateChanges() {
    return merge(this._data$, this._displayChange$).pipe(
      debounceTime(1000),
      tap(() => {
        if (this._enableStorage) {
          this.viewStorage.saveCurrentState(this._id, this.getState());
        }
      }),
    );
  }

  private observeSelectionChanges() {
    return merge(this._data$, this._selectionModel.changed).pipe(
      tap(() => {
        this.updateSelectionState();
      }),
    );
  }

  private observeDataChanges() {
    return merge(this._fetchState$).pipe(
      tap((state) => {
        // clear active id if no longer in dataset
        const activeId = this._activeId$.getValue();
        if (
          state.isFinalState() &&
          this._data$.getValue().items.length > 0 &&
          activeId &&
          !this.getItem(activeId)
        ) {
          this.clearActive();
        }
      }),
    );
  }

  private updateSelectionState() {
    if (this._selectionModel.selected.length === 0) {
      this.selectionState = 'none';
      return;
    }

    for (const item of this.items) {
      if (!this.isSelected(item)) {
        this.selectionState = 'some';
        return;
      }
    }
    this.selectionState = 'all';
  }

  private coerceItemId(itemOrId: any): I {
    if (typeof itemOrId === 'string' || typeof itemOrId === 'number') {
      return <any>itemOrId;
    } else {
      return this.getId(itemOrId);
    }
  }
}
