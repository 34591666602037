import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface DataFilterControlOptions {
  modelViewTransform?: (modelValue: any) => any;
  viewModelTransform?: (modelValue: any) => any;
}

export class DataFilterControl<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any,
> extends FormGroup<TControl> {
  private _lastValue: any = null;

  changes$: Observable<any>;

  constructor(
    controls: TControl,
    private options?: DataFilterControlOptions,
  ) {
    super(controls);

    this.changes$ = this.valueChanges.pipe(
      tap((value) => {
        this._lastValue = options?.viewModelTransform ? options.viewModelTransform(value) : value;
      }),
    );
  }

  resetValueFromCurrentFilter(value: any, emitEvent = false) {
    if (value === this._lastValue) return;

    const filter = this.options?.modelViewTransform
      ? this.options.modelViewTransform(value)
      : value;

    this.reset(
      Object.entries(this.controls).reduce((value, [key, control]) => {
        value[key] = (<any>filter)[key] ?? null;
        return value;
      }, {} as any),
      { emitEvent },
    );
  }
}
