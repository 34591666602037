import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FetchCountryGQL,
  FetchProvinceGQL,
  SearchCountriesGQL,
  SearchProvincesGQL,
} from './gql/country.service.gql.generated';
import { ICountryItem, IProvinceItem } from './types/country';

@Injectable()
export class CountryService {
  constructor(
    private countrySearchGQL: SearchCountriesGQL,
    private fetchCountryGQL: FetchCountryGQL,
    private provinceSearchGQL: SearchProvincesGQL,
    private fetchProvinceGQL: FetchProvinceGQL,
  ) {}

  fetchCountrySearch(search?: string | null): Observable<ICountryItem[]> {
    return this.countrySearchGQL.fetch({ search }).pipe(map((r) => r.data.countries));
  }

  fetchCountryByCode(code: string): Observable<ICountryItem> {
    return this.fetchCountryGQL.fetch({ countryCode: code }).pipe(map((r) => r.data.country));
  }

  fetchProvinceSearch(countryCode: string, search?: string | null): Observable<IProvinceItem[]> {
    return this.provinceSearchGQL.fetch({ countryCode, search }).pipe(map((r) => r.data.provinces));
  }

  fetchProvinceByCode(provinceCode: string): Observable<IProvinceItem> {
    return this.fetchProvinceGQL.fetch({ provinceCode }).pipe(
      map((r) => {
        if (!r.data)
          return {
            code: '',
            name: '',
          };

        return r.data.province;
      }),
    );
  }
}
