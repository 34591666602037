import { Injectable } from '@angular/core';
import { LocateAddressInput } from '@x/schemas/geocode';
import { map, Observable } from 'rxjs';
import {
  AutocompleteAddressGQL,
  ExpandAddressGQL,
  LocateAddressGQL,
} from './gql/geocode.service.gql.generated';
import {
  IAutocompleteAddressResult,
  IExpandedAddress,
  ILocateAddressResult,
} from './types/geocode';

@Injectable()
export class GeocodeService {
  constructor(
    private readonly autocompleteAddressGQL: AutocompleteAddressGQL,
    private readonly expandAddressGQL: ExpandAddressGQL,
    private readonly locateAddressGQL: LocateAddressGQL,
  ) {}

  autocompleteAddress(
    query: string,
    countryCode?: string | null,
    parentId?: string,
  ): Observable<IAutocompleteAddressResult> {
    return this.autocompleteAddressGQL
      .fetch({
        input: {
          query,
          countryCode,
          parentId,
        },
      })
      .pipe(map((r) => r.data.autocompleteAddress));
  }

  expandAddress(
    id: string,
    query: string,
    countryCode?: string | null,
  ): Observable<IExpandedAddress> {
    return this.expandAddressGQL
      .fetch({
        input: {
          id,
          query,
          countryCode,
        },
      })
      .pipe(map((r) => r.data.expandAddress));
  }

  locateAddress(address: LocateAddressInput): Observable<ILocateAddressResult> {
    return this.locateAddressGQL
      .fetch({
        input: {
          ...address,
        },
      })
      .pipe(map((r) => r.data.locateAddress));
  }
}
