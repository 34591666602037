import { FetchResult } from '@apollo/client/core';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * Map a fetch result's data, where the data will never be undefined
 */
export function mapFetchResultData<T, M>(
  mapFn: (value: T) => M,
): OperatorFunction<FetchResult<T>, M> {
  return pipe(
    filter((result) => result.data !== undefined && result.data !== null),
    map((result) => mapFn(result.data as T)),
  );
}
