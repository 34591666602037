import * as Types from '@x/schemas/geocode';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CountryItemFragment = { code: string, dialCode: string, name: string };

export type ProvinceItemFragment = { code: string, name: string };

export type SearchCountriesQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchCountriesQuery = { countries: Array<{ code: string, dialCode: string, name: string }> };

export type FetchCountryQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String']['input'];
}>;


export type FetchCountryQuery = { country: { code: string, dialCode: string, name: string } };

export type SearchProvincesQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchProvincesQuery = { provinces: Array<{ code: string, name: string }> };

export type FetchProvinceQueryVariables = Types.Exact<{
  provinceCode: Types.Scalars['String']['input'];
}>;


export type FetchProvinceQuery = { province: { code: string, name: string } };

export const CountryItemFragmentDoc = gql`
    fragment CountryItem on CountryObject {
  code
  dialCode
  name
}
    `;
export const ProvinceItemFragmentDoc = gql`
    fragment ProvinceItem on ProvinceObject {
  code
  name
}
    `;
export const SearchCountriesDocument = gql`
    query SearchCountries($search: String) {
  countries(search: $search) {
    ...CountryItem
  }
}
    ${CountryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCountriesGQL extends Apollo.Query<SearchCountriesQuery, SearchCountriesQueryVariables> {
    document = SearchCountriesDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchCountryDocument = gql`
    query FetchCountry($countryCode: String!) {
  country(countryCode: $countryCode) {
    ...CountryItem
  }
}
    ${CountryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchCountryGQL extends Apollo.Query<FetchCountryQuery, FetchCountryQueryVariables> {
    document = FetchCountryDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProvincesDocument = gql`
    query SearchProvinces($countryCode: String!, $search: String) {
  provinces(countryCode: $countryCode, search: $search) {
    ...ProvinceItem
  }
}
    ${ProvinceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProvincesGQL extends Apollo.Query<SearchProvincesQuery, SearchProvincesQueryVariables> {
    document = SearchProvincesDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProvinceDocument = gql`
    query FetchProvince($provinceCode: String!) {
  province(provinceCode: $provinceCode) {
    ...ProvinceItem
  }
}
    ${ProvinceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProvinceGQL extends Apollo.Query<FetchProvinceQuery, FetchProvinceQueryVariables> {
    document = FetchProvinceDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }