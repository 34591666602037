import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { ApolloClientOptions, ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  GraphClientConfig,
  GraphModuleConfig,
  GraphNamedClientConfig,
  GRAPH_CLIENT,
  GRAPH_DEFAULT_CLIENT,
  GRAPH_GLOBAL_LINK,
} from './graph.config';
import { LoggerLinkFactory } from './links/logger-link';
import { RetryLinkFactory } from './links/retry-link';

function createClient(
  httpLinkFactory: HttpLink,
  config: GraphClientConfig,
  middlewares: ApolloLink[],
) {
  const links: ApolloLink[] = [...middlewares];

  const errorLink = onError((error) => {
    console.error('GraphQL error', error);
  });

  links.push(errorLink);

  if (config.retry && config.retry.enabled) {
    const retryLink = RetryLinkFactory(config.retry);
    links.push(retryLink);
  }

  const httpLink = httpLinkFactory.create({
    uri: config.uri,
    includeExtensions: true,
  });

  links.push(httpLink);

  const client: ApolloClientOptions<any> = {
    ...config,
    cache: new InMemoryCache({
      addTypename: true,
      resultCaching: false,
    }),
    connectToDevTools: true,
    defaultOptions: { query: { fetchPolicy: 'network-only', errorPolicy: 'none' } },
    link: from(links),
  };

  return client;
}

function createNamedClients(
  httpLinkFactory: HttpLink,
  namedClients: GraphNamedClientConfig[],
  middlewares: ApolloLink[],
): NamedOptions {
  const namedOptions: NamedOptions = {};

  namedClients.forEach((config) => {
    namedOptions[config.name] = createClient(httpLinkFactory, config, middlewares);
  });

  return namedOptions;
}

@NgModule({
  imports: [ApolloModule, HttpClientModule],
})
export class GraphModule {
  static forRoot(options?: GraphModuleConfig): ModuleWithProviders<GraphModule> {
    const clientProviders: Provider[] =
      options?.clients?.map((client) => {
        return {
          provide: GRAPH_CLIENT,
          useValue: client,
          multi: true,
        };
      }) ?? [];

    clientProviders.push({
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createNamedClients,
      deps: [HttpLink, GRAPH_CLIENT, GRAPH_GLOBAL_LINK],
    });

    if (options?.client) {
      clientProviders.push({
        provide: GRAPH_DEFAULT_CLIENT,
        useValue: options.client,
      });

      clientProviders.push({
        provide: APOLLO_OPTIONS,
        useFactory: createClient,
        deps: [HttpLink, GRAPH_DEFAULT_CLIENT, GRAPH_GLOBAL_LINK],
      });
    }

    return {
      ngModule: GraphModule,
      providers: [
        ...clientProviders,
        {
          provide: GRAPH_GLOBAL_LINK,
          useFactory: LoggerLinkFactory,
          multi: true,
        },
      ],
    };
  }

  static forFeature(client: GraphNamedClientConfig): ModuleWithProviders<GraphModule> {
    return {
      ngModule: GraphModule,
      providers: [
        {
          provide: GRAPH_CLIENT,
          useValue: client,
          multi: true,
        },
      ],
    };
  }
}
