import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: string | undefined | null | Duration, ...args: any[]) {
    if (typeof value === 'string') {
      return Duration.fromISO(value).toHuman();
    }

    if (Duration.isDuration(value)) {
      return value.toHuman();
    }

    return null;
  }
}
