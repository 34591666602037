<div
  role="group"
  class="group-container"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  [formGroup]="parts"
>
  <span *ngFor="let key of format" class="input-element">
    <small>{{ key }}</small>
    <input type="number" [formControlName]="getControlKey(key)" min="0" placeholder="0" />
  </span>
</div>
