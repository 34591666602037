import { InjectionToken } from '@angular/core';
import { PointInput } from '@x/schemas/ecommerce';

export const MAP_CONFIG = new InjectionToken<MapConfig>('MAP_CONFIG');

export interface MapConfig {
  markerIconUser: string;
  markerIconRegion: string;
  polygonTheme: google.maps.PolygonOptions;
  theme: google.maps.MapTypeStyle[];
  defaultCoordinates: PointInput;
  iconMap: { [key: string]: string };
}
