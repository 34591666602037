import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { DataViewDirective } from './directives/data-view.directive';
import { DataViewFactoryService } from './services/data-view-factory.service';
import { DATA_TABLE_VIEW_STORAGE, IDataTableViewStorage } from './state/data-table-view-state';

export interface DataModuleConfig {
  viewStorageProvider: Type<IDataTableViewStorage>;
}

@NgModule({
  imports: [CommonModule],
  declarations: [DataViewDirective],
  exports: [DataViewDirective],
  providers: [],
})
export class DataModule {
  static forRoot(config: DataModuleConfig): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        DataViewFactoryService,
        {
          provide: DATA_TABLE_VIEW_STORAGE,
          useExisting: config.viewStorageProvider,
        },
      ],
    };
  }
}
