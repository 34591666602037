import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { LanguageService } from '@x/common/locale';
import { LanguageItem } from '@x/common/locale/language-registry';
import { AnyOperationState } from '@x/common/operation';
import { Observable, Subject, debounceTime, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'x-enum-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'enum-select.component.html',
  styleUrls: ['enum-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: EnumSelectComponent }],
  host: {
    class: 'x-enum-select',
  },
})
export class EnumSelectComponent<T = any, F = any, A = any>
  implements ControlValueAccessor, MatFormFieldControl<T>, OnDestroy, OnInit
{
  static nextId = 0;

  private readonly _destroy$ = new Subject<void>();

  @HostBinding()
  id = `x-enum-select-${EnumSelectComponent.nextId++}`;
  controlType = 'x-enum-select';

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    this.writeValue(value);
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return Array.isArray(this._value) ? this._value.length === 0 : !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  errorState = false;

  @Input('aria-describedby')
  userAriaDescribedBy: string;

  @Input()
  set multiple(multiple: any) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  get multiple() {
    return this._multiple;
  }
  _multiple = false;

  @Input()
  set nullable(nullable: any) {
    this._nullable = coerceBooleanProperty(nullable);
  }
  get nullable() {
    return this._nullable;
  }
  _nullable = true;

  @Input()
  nullLabel = 'Any';

  @Input()
  enum: string;

  @ViewChild(MatSelect)
  matSelect: MatSelect;

  get focused() {
    return this.matSelect?.focused ?? false;
  }

  options: LanguageItem[] = [];

  touched = false;
  stateChanges = new Subject<void>();

  resolveState$?: Observable<AnyOperationState<any>>;
  fetchState$: Observable<AnyOperationState<any>>;

  trackById = (i: number, op: LanguageItem) => op.id;
  compareWith = (o1?: string, o2?: string): boolean => {
    return o1 === o2;
  };
  displayWith = (o: LanguageItem) => o.label;

  _placeholder: string;

  _value: any;
  private _disabled = false;
  private _required = false;
  private _onChange: any = () => {};
  private _onTouch = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly lang: LanguageService,
    private readonly changeRef: ChangeDetectorRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.stateChanges
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.updateErrorState()),
      )
      .subscribe();
    if (this.enum) {
      let options = (this.options = this.lang.list(this.enum));
      this.stateChanges.next();
    }

    if (this.ngControl) {
      this.ngControl.valueChanges
        ?.pipe(
          takeUntil(this._destroy$),
          debounceTime(100),
          tap(() => this.updateErrorState()),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Model -> View change
   */
  writeValue(obj: any): void {
    this._value = obj;
    this.stateChanges.next();
    this.changeRef.markForCheck();
  }

  onOptionSelected(values: Array<string | number> | string | number) {
    this._value = Array.isArray(values) ? (values.length === 0 ? null : values) : values;

    this._onChange(this._value);
    this._onTouch();

    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    // const controlElement = this._elementRef.nativeElement
    //   .querySelector('.example-tel-input-container')!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if (this._disabled) return;
    this.matSelect.focus();
  }

  private updateErrorState() {
    const errorState = (this.ngControl.dirty && this.ngControl.invalid) ?? false;
    if (errorState !== this.errorState) this.errorState = errorState;
  }
}
