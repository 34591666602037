import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ContentChildren,
  Directive,
  HostBinding,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Directive({
  selector: '[xFormPanelDef]',
})
export class FormPanelDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'x-dashboard-form-layout',
  templateUrl: 'dashboard-form-layout.component.html',
  styleUrls: ['dashboard-form-layout.component.scss'],
  host: {
    class: 'x-dashboard-form-layout',
  },
})
export class DashboardFormLayoutComponent {
  @HostBinding('class')
  containerClass: 'container' | 'container-fluid' = 'container';

  @Input()
  set cols(cols: number | string) {
    this.rowColsClass = `row-cols-${cols}`;
  }

  rowColsClass: string | null = 'row-cols-1';

  @Input()
  set fluid(fluid: boolean | string) {
    this.containerClass = coerceBooleanProperty(fluid) ? 'container-fluid' : 'container';
  }

  @ContentChildren(FormPanelDefDirective)
  panels: QueryList<FormPanelDefDirective>;
}
