import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Duration } from 'luxon';
import { DurationLikeObject } from 'luxon/src/duration';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

type formatKeys = 'yr' | 'mth' | 'wk' | 'd' | 'hr' | 'min' | 'sec';

const FORMAT_MAP: Record<formatKeys, keyof DurationLikeObject> = {
  yr: 'years',
  mth: 'months',
  wk: 'weeks',
  d: 'days',
  hr: 'hours',
  min: 'minutes',
  sec: 'seconds',
};

const EMPTY_VALUE = {
  years: null,
  months: null,
  weeks: null,
  days: null,
  hours: null,
  minutes: null,
  seconds: null,
};

@Component({
  selector: 'x-duration-input-control',
  templateUrl: './duration-input-control.component.html',
  styleUrls: ['./duration-input-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: DurationInputControlComponent }],
  host: {
    '[class.label-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class DurationInputControlComponent
  implements ControlValueAccessor, MatFormFieldControl<any>, OnInit, OnDestroy
{
  private _destroy$ = new Subject<void>();

  @Input()
  get format(): formatKeys[] {
    return this._format;
  }
  set format(keys: formatKeys[]) {
    this._format = this.sortKeys(keys);
  }
  private _format: formatKeys[] = Object.keys(FORMAT_MAP) as formatKeys[];

  controlType = 'duration-input';

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  onChange = (_: any) => {};
  onTouched = () => {};
  parts = new UntypedFormGroup({
    years: new UntypedFormControl(null),
    months: new UntypedFormControl(null),
    weeks: new UntypedFormControl(null),
    days: new UntypedFormControl(null),
    hours: new UntypedFormControl(null),
    minutes: new UntypedFormControl(null),
    seconds: new UntypedFormControl(null),
  });

  static nextId = 0;

  @HostBinding() id = `${this.controlType}-${DurationInputControlComponent.nextId++}`;
  get empty() {
    let n = this.parts.value;
    return !n.years && !n.months && !n.weeks && !n.days && !n.hours && !n.minutes && !n.seconds;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    const duration = Duration.fromObject(this.parts.value, { locale: this.localeId });
    return duration.isValid ? duration.toISO() : null;
  }
  set value(string: string | null) {
    this.writeValue(string);
  }
  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  @HostListener('document:keydown.backspace', ['$event'])
  onKeydownHandler(evt: KeyboardEvent) {
    if (this.focused) {
      const el = evt.target as HTMLInputElement;

      // goto prev input if exist
      if (el.nodeName === 'INPUT' && !el.value) {
        const prevInput = el.parentElement?.previousSibling?.childNodes[1] as HTMLElement;
        if (prevInput && prevInput.nodeName === 'INPUT') {
          this._focusMonitor.focusVia(prevInput, 'program');
        }
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClickHandler(evt: MouseEvent) {
    if (this.focused) {
      const el = evt.target as HTMLInputElement;
      if (el.nodeName === 'SMALL') {
        const inp = el.nextSibling as HTMLElement;
        this._focusMonitor.focusVia(inp, 'program');
      }
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    @Inject(LOCALE_ID)
    private localeId: string,
  ) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  onContainerClick(event: MouseEvent): void {
    this.focused = true;
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.group-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(string: string | null): void {
    if (!string) {
      this.parts.reset({ emitEvent: false });
      this.stateChanges.next();
      return;
    }

    const duration = Duration.fromISO(string);

    if (duration.isValid) {
      this.parts.setValue({ ...EMPTY_VALUE, ...duration.toObject() }, { emitEvent: false });
    } else {
      this.parts.reset({ emitEvent: false });
    }
    this.stateChanges.next();
  }

  onFocusIn(event: FocusEvent) {
    this.focused = true;
    this.stateChanges.next();
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnInit(): void {
    this.parts.valueChanges
      .pipe(
        tap(() => this.onChange(this.empty ? null : this.value)),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getControlKey(key: formatKeys): keyof DurationLikeObject {
    return FORMAT_MAP[key];
  }

  // sort keys from year to second
  sortKeys(keys: formatKeys[]): formatKeys[] {
    try {
      let sortedKeys: formatKeys[] = [];
      let i = 0;
      while (i < keys.length) {
        for (const key of Object.keys(FORMAT_MAP)) {
          const hasKey = keys.find((k) => key === k && !sortedKeys.includes(key));
          if (hasKey) {
            sortedKeys = [...sortedKeys, hasKey];
            break;
          }
        }
        i = i + 1;
      }

      return sortedKeys;
    } catch (e) {
      return [];
    }
  }
}
