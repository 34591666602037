import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Time } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TimeInputComponent } from '@x/common/datetime/components/time-input/time-input.component';
import { coerceTimeValue } from '@x/common/utils';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'x-datetime-input',
  templateUrl: 'datetime-input.component.html',
  styleUrls: ['datetime-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DatetimeInputComponent,
    },
  ],
})
export class DatetimeInputComponent
  implements MatFormFieldControl<string | null>, ControlValueAccessor, OnInit
{
  static nextId = 0;
  controlType = 'x-time-input-control';

  get id(): string {
    return `${this.controlType}-${DatetimeInputComponent.nextId++}`;
  }

  placeholder: string = '';

  onChange: any = () => {};
  onTouched: any = () => {};

  get focused(): boolean {
    return (this.timeInput?.focused || this.dateInput?.focused) ?? false;
  }

  get empty(): boolean {
    return this.value === null;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  errorState = false;

  @ViewChild(MatInput, { static: true })
  dateInput?: MatInput;
  dateControl = new FormControl<string | null>(null);

  @ViewChild(TimeInputComponent, { static: true })
  timeInput?: TimeInputComponent;
  timeControl = new FormControl<Time | null>(null);

  @Input()
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  get required(): boolean {
    return this._required;
  }
  _required = false;

  @Input()
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  get disabled(): boolean {
    return this._disabled;
  }
  _disabled = false;

  @Input()
  set value(value: string | null) {
    this.writeValue(value);
  }
  get value(): string | null {
    return this._value?.toISO({ includeOffset: true }) ?? null;
  }
  _value: DateTime | null = null;

  formattedValue = '';

  stateChanges = new Subject<void>();
  private _destroy$ = new Subject<void>();

  constructor(
    private readonly changeRef: ChangeDetectorRef,
    @Optional() @Self() public readonly ngControl: NgControl,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.dateControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((date) => {
      this.onTouched();
      this.onChange(this.value);
      this.changeRef.markForCheck();
      this.stateChanges.next();
      this.updateView();
    });
  }

  writeValue(obj: string | null): void {
    this._value = obj ? DateTime.fromISO(obj) : null;
    const time = coerceTimeValue(this._value?.toFormat('HH:mm'));
    const date = this._value?.toFormat('yyyy-MM-dd') ?? null;
    this.timeControl.setValue(time);
    this.dateControl.setValue(date);
  }

  onContainerClick(event: MouseEvent): void {
    // TODO
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]): void {}

  private updateView() {
    this.formattedValue = this.value ?? 'hello?';
    // this.formattedValue = this._value?.toFormat('yyyy-MM-dd, HH:mm') ?? '';
  }
}
