<div
  role="group"
  class="address-autocomplete-container"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
>
  <input
    type="text"
    id="address_search"
    matInput
    #addressSearchInput
    autofocus
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="addressSearchControl"
    #tooltip="matTooltip"
    matTooltip="Could not resolve your address. Please use the form below."
    matTooltipClass="warn"
    [matTooltipDisabled]="disableTooltip$.value"
    (focusin)="addressSearchInputFocused$.next(true)"
    (focusout)="addressSearchInputFocused$.next(false)"
  />
  <ng-container *ngIf="addresses$ | async as addressSuggestions">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="
        overlayOriginElement ?? parentFormField?._elementRef ?? addressSearchInput
      "
      [cdkConnectedOverlayOpen]="(openSuggestions$ | async) ?? false"
      (overlayOutsideClick)="outsideClick($event)"
      [cdkConnectedOverlayWidth]="
        overlayOriginElement?.nativeElement.clientWidth ??
        parentFormField?._elementRef?.nativeElement.clientWidth ??
        addressSearchInput.clientWidth
      "
    >
      <x-address-suggestions-overlay
        [addresses]="addressSuggestions"
        (selectedSuggestion)="suggestionSelected($event)"
        [loaderTemplate]="searching$.value ? loaderTemplate : null"
      >
        <ng-container xSavedAddresses>
          <ng-content select="[xSavedAddresses]"></ng-content>
        </ng-container>
      </x-address-suggestions-overlay>
    </ng-template>

    <div class="loader" *ngIf="searching$ | async">
      <ng-container *ngTemplateOutlet="loaderTemplate"></ng-container>
    </div>
  </ng-container>
</div>
