import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'x-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  host: {
    class: 'x-search-field',
  },
})
export class SearchFieldComponent {
  @ViewChild('search') searchElement: ElementRef;

  focusSearch() {
    this.searchElement.nativeElement.firstChild.focus();
  }
}
