import { ChangeDetectionStrategy, Component, Inject, Type } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDataCollectionProvider, IDataProvider } from '@x/common/data';

export interface IDataAutocompleteDialogData {
  title: string;
  message?: string;
  okLabel: string;
  cancelLabel: string;
  multiple?: boolean;
  provider: Type<IDataCollectionProvider & IDataProvider>;
  value?: string | number;
  filter?: any;
}

export interface IDataAutocompleteDialogOptions {
  title?: string;
  message?: string;
  okLabel?: string;
  cancelLabel?: string;
  provider: Type<IDataCollectionProvider & IDataProvider>;
  value?: string | number;
  filter?: any;
  multiple?: boolean;
}

export interface IDataAutocompleteDialogResult {
  assign: boolean;
  value: string | number | null;
}

@Component({
  selector: 'x-data-autocomplete-dialog',
  templateUrl: 'data-autocomplete-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataAutocompleteDialogComponent {
  control = new UntypedFormControl(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IDataAutocompleteDialogData,
  ) {}
}
