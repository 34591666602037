<mat-select
  (valueChange)="onOptionSelected($event)"
  [multiple]="_multiple"
  [value]="_value"
  [compareWith]="compareWith"
  [placeholder]="_placeholder"
  [disabled]="disabled"
  panelClass="x-data-select-panel"
>
  <mat-option *ngIf="_nullable && !_multiple" [value]="null">{{ nullLabel }}</mat-option>
  <mat-option *ngFor="let option of options; trackBy: trackById" [value]="option.id">
    {{ option.label }}
  </mat-option>
</mat-select>
