<h1 mat-dialog-title>{{ data.title | l }}</h1>
<div mat-dialog-content>
  {{ data.message | l }}
</div>
<div mat-dialog-actions align="end">
  <button
    *ngFor="let action of data.actions"
    mat-flat-button
    (click)="selectAction(action)"
    [color]="action.color"
  >
    {{ action.name | l }}
  </button>
</div>
