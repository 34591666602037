import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { FormErrorBoundaryDirective } from '@x/common/form';

@Directive({
  selector: '[xFormError]',
})
export class FormErrorDirective implements OnInit {
  @Input('xFormError')
  errorKey: string;

  @HostBinding('innerHTML')
  message: string | null;

  constructor(private errorBoundary: FormErrorBoundaryDirective) {}

  ngOnInit() {
    if (this.errorBoundary) {
      this.message = this.errorBoundary.errors.get(this.errorKey);

      this.errorBoundary.errorChanges.subscribe(
        (errors) => (this.message = errors.get(this.errorKey)),
      );
    } else {
      console.warn('FormErrorDirective: no FormErrorBoundaryDirective injected.');
    }
  }
}
