<mat-chip-grid #chipList aria-label="Fruit selection">
  <ng-container *ngFor="let option of objects ?? []; trackBy: trackFn ?? defaultTrackFn">
    <mat-chip-option [removable]="removable" (removed)="onOptionRemove(option)">
      <ng-container
        [ngTemplateOutlet]="triggerTemplate?.template ?? defaultTriggerTemplate"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-container>
      <fa-icon matChipRemove *ngIf="removable" icon="minus-circle"></fa-icon>
    </mat-chip-option>
  </ng-container>

  <input
    type="text"
    #input
    class="model-autocomplete-input"
    [placeholder]="_placeholder"
    [formControl]="control"
    [matAutocomplete]="modelAutocomplete"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    [id]="id + '-input'"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
    (matChipInputTokenEnd)="add($event)"
  />
</mat-chip-grid>
<mat-autocomplete
  #modelAutocomplete="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onOptionSelected($event)"
>
  <mat-option
    *ngFor="let option of filteredOptions$ | async; trackBy: trackFn ?? defaultTrackFn"
    [value]="option"
  >
    <ng-container
      [ngTemplateOutlet]="optionTemplate?.template ?? defaultOptionTemplate"
      [ngTemplateOutletContext]="{ $implicit: option }"
    ></ng-container>
  </mat-option>
</mat-autocomplete>

<ng-template #defaultOptionTemplate let-option>
  <span>{{ displayWith(option) }}</span>
</ng-template>

<ng-template #defaultTriggerTemplate let-option>
  <span>{{ displayWith(option) }}</span>
</ng-template>
