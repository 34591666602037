import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslatedError } from '@x/common/error';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorDialogService {
  constructor(private matDialog: MatDialog) {}

  open(error: TranslatedError) {
    return this.matDialog.open<ErrorDialogComponent, TranslatedError>(ErrorDialogComponent, {
      data: error,
    });
  }
}
