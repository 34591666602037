import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ITextInputDialogData {
  title: string;
  placeholder?: string;
  okLabel: string;
  cancelLabel: string;
  value?: string | null;
  long?: boolean;
}

export interface ITextInputDialogOptions {
  title?: string;
  placeholder?: string;
  okLabel?: string;
  cancelLabel?: string;
  value?: string | null;
  long?: boolean;
}

export interface ITextInputDialogResult {
  assign: boolean;
  value: string | null;
}

@Component({
  selector: 'x-text-input-dialog',
  templateUrl: 'text-input-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['text-input-dialog.component.scss'],
})
export class TextInputDialogComponent {
  long = this.data.long ?? false;

  control = new UntypedFormControl(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ITextInputDialogData,
    public dialog: MatDialogRef<ITextInputDialogData, ITextInputDialogResult>,
  ) {}
}
