import * as Types from '@x/schemas/geocode';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AutocompleteAddressSuggestionFragment = { id: string, label: string, subLabel?: string | null | undefined, type: string, size?: number | null | undefined, provider: string };

export type AutocompleteAddressResultFragment = { countryCode: string, query: string, parentId?: string | null | undefined, suggestions: Array<{ id: string, label: string, subLabel?: string | null | undefined, type: string, size?: number | null | undefined, provider: string }> };

export type AutocompleteAddressQueryVariables = Types.Exact<{
  input: Types.AutocompleteAddressInput;
}>;


export type AutocompleteAddressQuery = { autocompleteAddress: { countryCode: string, query: string, parentId?: string | null | undefined, suggestions: Array<{ id: string, label: string, subLabel?: string | null | undefined, type: string, size?: number | null | undefined, provider: string }> } };

export type ExpandedAddressFragment = { id: string, full: string, type: Types.AddressType, streetNumber?: string | null | undefined, streetName?: string | null | undefined, street: string, establishment?: string | null | undefined, complex?: string | null | undefined, suburb?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postcode?: string | null | undefined, province?: string | null | undefined, provinceCode?: string | null | undefined, country?: string | null | undefined, countryCode?: string | null | undefined, lat?: number | null | undefined, lng?: number | null | undefined, provider: string };

export type ExpandAddressQueryVariables = Types.Exact<{
  input: Types.ExpandAddressInput;
}>;


export type ExpandAddressQuery = { expandAddress: { id: string, full: string, type: Types.AddressType, streetNumber?: string | null | undefined, streetName?: string | null | undefined, street: string, establishment?: string | null | undefined, complex?: string | null | undefined, suburb?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postcode?: string | null | undefined, province?: string | null | undefined, provinceCode?: string | null | undefined, country?: string | null | undefined, countryCode?: string | null | undefined, lat?: number | null | undefined, lng?: number | null | undefined, provider: string } };

export type LocateAddressResultFragment = { lat: number, lng: number, provider: string };

export type LocateAddressQueryVariables = Types.Exact<{
  input: Types.LocateAddressInput;
}>;


export type LocateAddressQuery = { locateAddress: { lat: number, lng: number, provider: string } };

export const AutocompleteAddressSuggestionFragmentDoc = gql`
    fragment AutocompleteAddressSuggestion on AutocompleteAddressSuggestionObject {
  id
  label
  subLabel
  type
  size
  provider
}
    `;
export const AutocompleteAddressResultFragmentDoc = gql`
    fragment AutocompleteAddressResult on AutocompleteAddressResultObject {
  countryCode
  query
  parentId
  suggestions {
    ...AutocompleteAddressSuggestion
  }
}
    ${AutocompleteAddressSuggestionFragmentDoc}`;
export const ExpandedAddressFragmentDoc = gql`
    fragment ExpandedAddress on ExpandedAddressObject {
  id
  full
  type
  streetNumber
  streetName
  street
  establishment
  complex
  suburb
  city
  region
  postcode
  province
  provinceCode
  country
  countryCode
  lat
  lng
  provider
}
    `;
export const LocateAddressResultFragmentDoc = gql`
    fragment LocateAddressResult on LocateAddressResultObject {
  lat
  lng
  provider
}
    `;
export const AutocompleteAddressDocument = gql`
    query AutocompleteAddress($input: AutocompleteAddressInput!) {
  autocompleteAddress(input: $input) {
    ...AutocompleteAddressResult
  }
}
    ${AutocompleteAddressResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AutocompleteAddressGQL extends Apollo.Query<AutocompleteAddressQuery, AutocompleteAddressQueryVariables> {
    document = AutocompleteAddressDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExpandAddressDocument = gql`
    query ExpandAddress($input: ExpandAddressInput!) {
  expandAddress(input: $input) {
    ...ExpandedAddress
  }
}
    ${ExpandedAddressFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExpandAddressGQL extends Apollo.Query<ExpandAddressQuery, ExpandAddressQueryVariables> {
    document = ExpandAddressDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LocateAddressDocument = gql`
    query LocateAddress($input: LocateAddressInput!) {
  locateAddress(input: $input) {
    ...LocateAddressResult
  }
}
    ${LocateAddressResultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LocateAddressGQL extends Apollo.Query<LocateAddressQuery, LocateAddressQueryVariables> {
    document = LocateAddressDocument;
    client = 'geocode';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }