import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IPromptDialogActionData<R = boolean> {
  name: string;
  result: R;
  color?: string;
}

export interface IPromptDialogData<R = boolean> {
  title: string;
  message: string;
  actions: IPromptDialogActionData<R>[];
}

@Component({
  selector: 'x-prompt-dialog',
  templateUrl: 'prompt-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['prompt-dialog.component.scss'],
})
export class PromptDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPromptDialogData,
  ) {}

  selectAction(action: IPromptDialogActionData) {
    this.dialogRef.close(action.result);
  }
}
