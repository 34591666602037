<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p class="mat-hint mat-body-2" *ngIf="data.message">{{ data.message }}</p>
  <x-data-select
    [multiple]="data.multiple ?? false"
    [provider]="data.provider"
    [formControl]="control"
    [filter]="data.filter"
    [placeholder]="data.placeholder"
  ></x-data-select>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="{ assign: false, value: control.value }">
    {{ data.cancelLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="{ assign: true, value: control.value }"
  >
    {{ data.okLabel }}
  </button>
</div>
