import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { TranslatedError } from '@x/common/error';
import { Operation, OperationObserverService } from '@x/common/operation';
import { BehaviorSubject, Observable, Subject, Subscription, isObservable, merge, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DataFilterControl } from '../form/data-filter-control';
import { DataSearchControl } from '../form/data-search-control';
import { IDataCollection, IDataPageModel, IDataSortModel } from '../types/data-model';
import { IDataCollectionProvider } from '../types/data-provider';
import { IDataQuery } from '../types/data-query';

const EMPTY_DATA = Object.freeze({ totalItemsCount: 0, items: [] });

export interface IDataCollectionViewOptions<T = any, F = any, A = any> {
  entityName?: string;
  filter?: F;
  args?: A;
  page?: IDataPageModel;
  sort?: IDataSortModel;
  filterFixture?: (filter: F) => F | null | undefined;
  filterSubject?: Observable<F | null | undefined>;
  filterControl?: DataFilterControl | null | undefined;
  searchControl?: DataSearchControl | null | undefined;
  displayOptions?: Record<string, any>;
}

export class DataCollectionView<T = any, F = any, A = any, I = string | number>
  implements DataSource<T>
{
  get searchText() {
    return this._searchText;
  }
  set searchText(text: string | null) {
    this.setSearchText(text);
  }

  get filter(): F {
    return this._filter;
  }
  set filter(filter: F) {
    this.setFilter(filter);
  }

  get args(): A {
    return this._args;
  }
  set args(args: A) {
    this.setArgs(args);
  }

  get pageIndex(): number {
    return this._page.index;
  }
  set pageIndex(index: number) {
    this.setPage(index);
  }

  get pageSize(): number {
    return this._page.size;
  }
  set pageSize(size: number) {
    this.setPage(this.pageIndex, size);
  }

  get sortColumn(): string {
    return this._sort.column;
  }
  set sortColumn(column: string) {
    this.setSort(column);
  }

  get sortOrder(): 'asc' | 'desc' | string {
    return this._sort.order;
  }
  set sortOrder(order: 'asc' | 'desc' | string) {
    this.setSort(this._sort.column, order);
  }

  get displayOptions(): any {
    return this._displayOptions;
  }
  set displayOptions(options: any) {
    this.setDisplayOptions(options);
  }

  get fetchLoading() {
    return this._fetchState$.getValue().status === 'loading';
  }

  get fetchError(): TranslatedError | undefined | null {
    return this._fetchState$.getValue().error;
  }

  get items(): T[] {
    return this._data$.getValue().items;
  }

  get totalItemsCount(): number {
    return this._data$.getValue().totalItemsCount;
  }

  get defaultPageSize() {
    return this._defaultPage?.size ?? 5;
  }

  public trackById = (i: number, item: T) => this.getId(item);

  protected _fetchState$ = Operation.createBehaviourSubject<IDataCollection<T>>();

  protected _queryChange$ = new Subject<boolean>();
  protected _displayChange$ = new Subject<void>();
  protected _data$ = new BehaviorSubject<IDataCollection<T>>({
    items: [],
    totalItemsCount: 0,
  });

  protected _debounceTime: number = 300;

  protected _searchText: string | null = null;
  protected _filter: F;
  protected _args: A;
  protected _sort: IDataSortModel = { column: '', order: 'asc' };
  protected _page: IDataPageModel = { size: 5, index: 0 };
  protected _displayOptions: Record<string, any> | null = null;
  protected _entityName?: string;

  protected _filterFixture?: ((filter: F) => F | null | undefined) | null;
  protected _filterSubject: Observable<F | null | undefined>;
  protected _filterSubjectValue: F | null | undefined;
  protected _filterControl: DataFilterControl | null | undefined;
  protected _searchControl: DataSearchControl | null | undefined;
  protected _defaultFilter: F;
  protected _defaultArgs: A;
  protected _defaultSort: IDataSortModel = { column: '', order: 'asc' };
  protected _defaultPage: IDataPageModel = { size: 5, index: 0 };
  protected _defaultDisplayOptions: any = null;

  protected _subscriptions: Subscription[] = [];
  protected _observables: Observable<any>[] = [];

  protected _connected = false;

  constructor(
    public collectionProvider: IDataCollectionProvider<T, F, A, I>,
    protected operationService: OperationObserverService,
    options?: IDataCollectionViewOptions<T, F, A>,
  ) {
    this._defaultArgs = this._args =
      options?.args ?? <any>collectionProvider.defaultArguments ?? {};

    this._defaultFilter = this._filter =
      options?.filter ?? <any>collectionProvider.defaultFilter ?? {};

    this._defaultPage = this._page = options?.page ??
      collectionProvider.defaultPage ?? { index: 0, size: 10 };

    this._defaultSort = this._sort = options?.sort ??
      collectionProvider.defaultSort ?? { column: '', order: 'asc' };

    this._defaultDisplayOptions = this._displayOptions = options?.displayOptions ?? null;

    this._filterFixture = options?.filterFixture ?? null;
    this._filterSubject = options?.filterSubject ?? of(null);
    this._filterControl = options?.filterControl ?? null;

    if (options?.filterControl) {
      this.setFilterControl(options.filterControl);
    }
    if (options?.searchControl) {
      this.setSearchControl(options.searchControl);
    }

    this._entityName = options?.entityName;

    if (options?.filterSubject) {
      this.bindObservable(this.observeFilterSubject());
    }

    this.bindObservable(this.observeFetch());
  }

  connect(collectionViewer?: CollectionViewer): Observable<T[]> {
    if (!this._connected) {
      this.subscribeAll();
    }

    this._connected = true;

    return this._data$.pipe(map((r) => r.items));
  }

  disconnect(collectionViewer?: CollectionViewer): void {
    if (this._connected) {
      this.unsubscribeAll();
    }
    this._connected = false;
  }

  getId(obj: any): I {
    return this.collectionProvider.toId(obj);
  }

  toString(obj: T): string {
    return this.collectionProvider.toString(obj);
  }

  setFilter(filter: F) {
    this._filter = filter;
    this._page.index = 0;
    this._queryChange$.next(true);
  }

  setFilterControl(control: DataFilterControl) {
    this._filterControl = control;

    this.bindObservable(
      this._filterControl.changes$.pipe(
        tap((filter) => {
          this.setFilter(filter);
        }),
      ),
    );

    this.bindObservable(
      this.queryChanges().pipe(
        startWith(true),
        tap(() => {
          this._filterControl?.resetValueFromCurrentFilter(this._filter);
        }),
      ),
    );

    this._filterControl?.resetValueFromCurrentFilter(this._filter);
  }

  setSearchText(text: string | null) {
    this._searchText = text;
    this._page.index = 0;
    this._queryChange$.next(true);
  }

  setSearchControl(control: DataSearchControl) {
    this._searchControl = control;
    this.bindObservable(
      this._searchControl.changes$.pipe(
        tap((searchText) => {
          this.setSearchText(searchText);
        }),
      ),
    );
    this.bindObservable(
      this.queryChanges().pipe(
        startWith(true),
        tap(() => {
          this._searchControl?.resetValueFromCurrentValue(this);
        }),
      ),
    );
    this._searchControl?.resetValueFromCurrentValue(this);
  }

  patchFilter(filter: Partial<F>) {
    this.setFilter({
      ...this._filter,
      ...filter,
    });
  }

  setArgs(args: A) {
    this._args = args;
    this._queryChange$.next(true);
  }

  setDisplayOption(option: string, value: any) {
    this.setDisplayOptions({
      ...this._displayOptions,
      [option]: value,
    });
    this._displayChange$.next();
  }

  setDisplayOptions(options: any) {
    this._displayOptions = options;
    this._displayChange$.next();
  }

  patchDisplayOptions(options: any) {
    this.setDisplayOptions({
      ...this._displayOptions,
      ...options,
    });
  }

  patchArgs(args: Partial<A>) {
    this.setArgs({
      ...this._args,
      ...args,
    });
  }

  setPage(page: IDataPageModel): void;
  setPage(event: PageEvent): void;
  setPage(index: number, size?: number): void;

  setPage(index: number | IDataPageModel | PageEvent, size?: number): void {
    if (typeof index === 'number') {
      this._page = {
        index,
        size: size ?? this._page.size,
      };
    } else if (typeof index === 'object' && 'pageIndex' in index && 'pageSize' in index) {
      this._page = {
        index: index.pageIndex,
        size: index.pageSize,
      };
    } else {
      this._page = { ...index };
    }
    this._queryChange$.next(false);
  }

  setSort(sort: IDataSortModel): void;
  setSort(column: string | null, order?: 'asc' | 'desc' | string | null): void;
  setSort(column: string | null | IDataSortModel, order?: 'asc' | 'desc' | string | null): void {
    if (typeof column === 'string' || column === null) {
      this._sort = {
        column: column ?? this._defaultSort.column,
        order: order ?? this._defaultSort.order,
      };
    } else {
      this._sort = { ...column };
    }
    this._page.index = 0;
    this._queryChange$.next(false);
  }

  reset() {
    this._page = { ...this._defaultPage };
    this._sort = { ...this._defaultSort };
    this._filter = { ...this._defaultFilter };
    this._args = { ...this._defaultArgs };
    this._searchText = null;
    this._displayChange$.next();
    this._queryChange$.next(true);
  }

  getQuery(): IDataQuery<F, A> {
    const fixture = this._filterFixture ? this._filterFixture(this._filter) : {};
    const subject = this._filterSubjectValue ?? {};

    return {
      page: this._page,
      sort: this._sort,
      filter: { ...this._filter, ...fixture, ...subject },
      args: this._args,
      searchText: this._searchText,
    };
  }

  queryChanges(): Observable<boolean> {
    return this._queryChange$.asObservable();
  }

  stateChanges(): Observable<void> {
    return merge(
      this._displayChange$,
      this._queryChange$,
      this._data$.pipe(skip(1)),
      this._fetchState$.pipe(skip(1)),
    ).pipe(
      map((a) => {
        return;
      }),
    );
  }

  filterChanges(): Observable<F | null> {
    return this._queryChange$.pipe(
      map(() => {
        return this.getQuery().filter ?? null;
      }),
      distinctUntilChanged(),
    );
  }

  fetchState() {
    return this._fetchState$.asObservable();
  }

  data(): Observable<IDataCollection<T>> {
    return this._data$.asObservable();
  }

  item(id: I): Observable<T | null> {
    return this._data$.pipe(map((c) => this.getItem(id)));
  }

  getItem(id: I): T | null {
    return this._data$.getValue().items.find((item) => this.getId(item) === id) ?? null;
  }

  refresh() {
    this._queryChange$.next(true);
  }

  bindObservable(observable: Observable<any>) {
    this._observables.push(observable);
    if (this._connected) {
      this._subscriptions.push(observable.subscribe());
    }
  }

  private observeFetch() {
    // required to not debounce the first emit
    const initialQuery$ = this._queryChange$.pipe(first(), startWith(true));
    const debouncedQuery$ = this._queryChange$.pipe(skip(1), debounceTime(this._debounceTime));

    const result$ = merge(initialQuery$, debouncedQuery$).pipe(
      map((refresh) => [this.getQuery(), refresh] as [IDataQuery<F, A>, boolean]),
      switchMap(([query, refresh]) => {
        let fetch: Observable<IDataCollection<T>> | IDataCollection<T>;
        fetch = this.collectionProvider.fetchCollection(query);

        const observeOptions = {
          label: `Fetch ${this._entityName ?? ''} Collection`,
          errorActions: [{ label: 'Retry', do: () => this.refresh() }],
        };

        if (isObservable(fetch)) {
          return this.operationService.observe(fetch, observeOptions);
        } else {
          return this.operationService.observe(of(fetch), observeOptions);
        }
      }),
      tap((op) => {
        if (op.isSuccessState()) {
          this._data$.next(op.data);
        } else if (op.isErrorState()) {
          this._data$.next(EMPTY_DATA);
        }
        this._fetchState$.next(op);
      }),
    );

    return result$;
  }

  private observeFilterSubject() {
    return this._filterSubject.pipe(
      tap((filterSubjectValue) => {
        this._filterSubjectValue = filterSubjectValue;
        this._queryChange$.next(true);
      }),
    );
  }

  private subscribeAll() {
    this._subscriptions = this._observables.map((o) => o.subscribe());
  }

  private unsubscribeAll() {
    this._subscriptions.forEach((s) => (s.closed ? s.unsubscribe() : null));
    this._subscriptions = [];
  }
}
