import { LanguageDeclaration } from '@x/common/locale';

export const DIALOG_LANGUAGE: LanguageDeclaration = {
  dialog: {
    title: {
      confirm: 'Confirm Action',
    },
    message: {
      confirm: 'Are you sure you want to perform this action?',
    },
    action: {
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      cancel: 'Cancel',
    },
  },
};
