const TIMEZONES = ['UTC', 'Africa/Johannesburg', 'Europe/London'];

export class Timezone {
  static list(): string[] {
    if (!(<any>Intl).supportedValuesOf) {
      return TIMEZONES;
    } else {
      return [...TIMEZONES, ...(<any>Intl).supportedValuesOf('timeZone')];
    }
  }

  static localZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
