import { coerceDateTime } from '@x/common/utils';
import { BehaviorSubject, Subject } from 'rxjs';

const NAMED_FORMATS: Record<string, string> = {
  short: 'yyyy-MM-dd HH:mm',
  medium: 'd MMM y HH:mm',
  long: 'd MMMM, y HH:mm:ss ',
  full: 'EEEE, d MMMM y HH:mm:ss.u ZZZZ',
  shortDate: 'yyyy-MM-dd',
  mediumDate: 'd MMM y',
  longDate: 'EEE, d MMM y',
  fullDate: 'EEEE, d MMMM y',
  shortTime: 'HH:mm',
  mediumTime: 'HH:mm:ss',
  longTime: 'HH:mm:ss ZZZZ',
  fullTime: 'HH:mm:ss.u ZZZZ',
};

export interface DateFormatInput {
  value: any;
  format?: string;
  timezone?: string;
}

export interface DateFormatOptions {}

export class DateTimeFormatOptionsSubject extends BehaviorSubject<DateFormatOptions> {
  fork() {}
}

export class DatetimeFormatter {
  private timezone: string = 'UTC';
  private format = 'medium';
  public changes$ = new Subject<void>();

  constructor(private options: { format: string; timezone: string; parent?: DatetimeFormatter }) {
    this.timezone = options.timezone;
    this.format = options.format;
  }

  toFormat(value: any, format?: string, timezone?: string): string {
    if (!value) return '';

    format = format ?? this.format;
    timezone = timezone ?? this.timezone;

    let dateTime = coerceDateTime(value, { zone: timezone });

    if (!dateTime) return '';
    if (!dateTime.isValid) return 'invalid';

    if (format && format in NAMED_FORMATS) {
      return dateTime.toFormat(NAMED_FORMATS[format]);
    }

    return dateTime.toFormat(format);
  }

  setTimezone(timezone: string): void {
    this.timezone = timezone;
    this.changes$.next();
  }

  setFormat(format: string): void {
    this.format = format;
    this.changes$.next();
  }

  getTimezone(): string {
    return this.timezone;
  }

  getFormat(): string {
    return this.format;
  }

  getNamedFormats(): string[] {
    return Object.keys(NAMED_FORMATS);
  }

  fork() {
    return new DatetimeFormatter({
      timezone: this.timezone,
      format: this.format,
    });
  }

  private formatInput(input: DateFormatInput) {
    return this.toFormat(input.value, input.format, input.timezone);
  }
}
