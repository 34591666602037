import { CollectionViewer } from '@angular/cdk/collections';
import { Operation, OperationObserverService } from '@x/common/operation';
import { BehaviorSubject, isObservable, merge, Observable, of, Subject } from 'rxjs';
import { map, skip, startWith, tap } from 'rxjs/operators';
import { IDataCollectionProvider, IDataProvider } from '../types/data-provider';
import { DataCollectionView, IDataCollectionViewOptions } from './data-collection-view';

export class DataSelectOptionObject<T = any> {
  constructor(public item: T, public id: string | number, public display: string) {}
}

export class DataSelectView<T = any, F = any, A = any> extends DataCollectionView<T, F, A> {
  public collectionProvider: IDataCollectionProvider<T, F, A> & IDataProvider<T>;

  get options() {
    return this._options$.getValue();
  }

  get selectedOptions() {
    return this._selectedOptions;
  }

  private _selectedOptions: DataSelectOptionObject<T>[] = [];
  private _selectedOptionChanges$ = new Subject<void>();
  private _resolveState$ = Operation.createBehaviourSubject();
  private _options$ = new BehaviorSubject<DataSelectOptionObject<T>[]>([]);

  constructor(
    provider: IDataCollectionProvider<T, F, A> & IDataProvider<T>,
    operationService: OperationObserverService,
    options?: IDataCollectionViewOptions,
  ) {
    super(provider, operationService, options);

    this.bindObservable(
      this._data$.pipe(map((data) => this._options$.next(this.makeOptions(data.items)))),
    );
  }

  disconnect(collectionViewer?: CollectionViewer) {
    super.disconnect();
    this._options$.complete();
    this._resolveState$.complete();
  }

  fetchSingle(id: string | number) {
    return this.collectionProvider.fetchSingle(id);
  }

  clearSelection() {
    this._selectedOptions = [];
    this._selectedOptionChanges$.next();
  }

  selectMany(options: Array<DataSelectOptionObject<T>>, clearSelection = true) {
    this._selectedOptions = clearSelection ? options : [...this._selectedOptions, ...options];
    this._selectedOptionChanges$.next();
  }

  select(option: DataSelectOptionObject<T>, clearSelection = false) {
    if (this.isSelected(option)) return;
    if (clearSelection) this._selectedOptions = [];
    this._selectedOptions = [...this._selectedOptions, option];
    this._selectedOptionChanges$.next();
  }

  deselect(option: DataSelectOptionObject<T>) {
    if (!this.isSelected(option)) return;
    this._selectedOptions = [...this.selectedOptions.filter((o) => o.id !== option.id)];
    this._selectedOptionChanges$.next();
  }

  selectById(id: string | number) {
    if (this.isSelectedId(id)) return;
    this.resolveSelection([id]);
  }

  selectManyById(ids: Array<string | number>, clearSelection = true) {
    if (clearSelection) this._selectedOptions = [];
    const unselected = ids.filter((id) => !this.isSelectedId(id));
    if (unselected.length) this.resolveSelection(unselected);
  }

  isSelected(option: DataSelectOptionObject<T>) {
    return this._selectedOptions.some((o) => o.id === option.id);
  }

  isSelectedId(id: number | string) {
    return this._selectedOptions.some((o) => o.id === id);
  }

  resolveState() {
    return this._resolveState$.asObservable();
  }

  resetResolveState() {
    this._resolveState$.next(Operation.create());
  }

  getSelectedIds() {
    return this.selectedOptions.map((o) => o.id);
  }

  selectionChanges() {
    return this._selectedOptionChanges$.asObservable();
  }

  firstSelection(): Observable<DataSelectOptionObject | null> {
    return this._selectedOptionChanges$.pipe(
      startWith(1),
      map(() => this.selectedOptions[0] ?? null),
    );
  }

  selections() {
    return this._selectedOptionChanges$.pipe(
      startWith(1),
      map(() => this.selectedOptions),
    );
  }

  stateChanges() {
    return merge(
      this._displayChange$,
      this._queryChange$,
      this._data$.pipe(skip(1)),
      this._fetchState$.pipe(skip(1)),
      this._selectedOptionChanges$,
      this._resolveState$,
      this._options$,
    ).pipe(
      map((a) => {
        return;
      }),
    );
  }

  private resolveSelection(ids: Array<string | number>) {
    this.resetResolveState();
    let operation$ = merge(
      ...ids.map((id) => {
        let fetch$ = this.fetchSingle(id);

        if (isObservable(fetch$)) {
          return fetch$;
        } else {
          return of(fetch$);
        }
      }),
    ).pipe(
      tap((item) => {
        this.select(this.makeOption(item));
      }),
    );

    this.operationService.observe(operation$).subscribe((s) => this._resolveState$.next(s));
  }

  private makeOptions(items: T[]): Array<DataSelectOptionObject<T>> {
    return items.map((i) => this.makeOption(i));
  }

  private makeOption(item: T): DataSelectOptionObject<T> {
    return new DataSelectOptionObject(item, this.getId(item), this.toString(item));
  }
}
