import { TrackByFunction } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class ModelAutocompleteDatasource<T = any, A = any> {
  constructor(public trackByFn: TrackByFunction<T>) {}
  abstract connect(
    searchText: Observable<string | null>,
    searchArguments?: Observable<A>,
  ): Observable<T[]>;
  abstract disconnect(): void;
}
