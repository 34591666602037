import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface INumberInputDialogData {
  title: string;
  placeholder?: string;
  okLabel: string;
  cancelLabel: string;
  value?: number | null;
  required?: boolean;
  min?: number;
  max?: number;
}

export interface INumberInputDialogOptions {
  title?: string;
  placeholder?: string;
  okLabel?: string;
  cancelLabel?: string;
  value?: number | null;
  required?: boolean;
  min?: number;
  max?: number;
}

export interface INumberInputDialogResult {
  assign: boolean;
  value: number | null;
}

@Component({
  selector: 'x-number-input-dialog',
  templateUrl: 'number-input-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['number-input-dialog.component.scss'],
})
export class NumberInputDialogComponent {
  control = new FormControl<number | null>(this.data.value ?? null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: INumberInputDialogData,
  ) {}
}
